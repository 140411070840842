import { all, takeLatest } from 'redux-saga/effects';
import {
  campSearchAutoCompleteFailure,
  campSearchAutoCompleteRequest,
  campSearchAutoCompleteSuccess,
  getSearchFilterInfoFailure,
  getSearchFilterInfoRequest,
  getSearchFilterInfoSuccess,
  getInventoryFiltersRequest,
  getInventoryFiltersSuccess,
  getInventoryFiltersFailure,
} from 'store/reducers/searchFilter';
import {
  campSearchAutoComplete,
  getInventoryFilters,
  getSearchFilterInfo,
} from 'api';
import { createFetchAction } from 'store/sagas/createFetchAction';

function* getSearchFilterInfoSaga() {
  yield takeLatest(
    getSearchFilterInfoRequest.type,
    createFetchAction(
      getSearchFilterInfo,
      getSearchFilterInfoSuccess,
      getSearchFilterInfoFailure,
    ),
  );
}

function* campSearchAutoCompleteSaga() {
  yield takeLatest(
    campSearchAutoCompleteRequest.type,
    createFetchAction(
      campSearchAutoComplete,
      campSearchAutoCompleteSuccess,
      campSearchAutoCompleteFailure,
    ),
  );
}

function* getInventoryFiltersSaga() {
  yield takeLatest(
    getInventoryFiltersRequest.type,
    createFetchAction(
      getInventoryFilters,
      getInventoryFiltersSuccess,
      getInventoryFiltersFailure,
    ),
  );
}

export default function* searchFilterSaga() {
  yield all([
    getSearchFilterInfoSaga(),
    campSearchAutoCompleteSaga(),
    getInventoryFiltersSaga(),
  ]);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoreHomeState } from 'store/types';
import {
  IStoreCollection,
  IStoreShowcase,
  IStoreTheme,
} from 'types/store/storeTypes';

const initialState: IStoreHomeState = {
  collectionList: [],
  showcaseList: [],
  themeList: [],
  requestStatus: {
    showcaseListStatus: {
      isRequested: false,
      isLoggedIn: false,
    },
    themeListStatus: {
      isRequested: false,
      isLoggedIn: false,
    },
    collectionListStatus: {
      isRequested: false,
      isLoggedIn: false,
    },
  },
  showcaseIndex: 0,
  error: null,
};

export const camfitStoreHomeSlice = createSlice({
  name: 'camfitStoreHome',
  initialState,
  reducers: {
    getStoreCollectionListRequest: (state, action) => {
      state.error = null;
    },
    getStoreCollectionListSuccess: (
      state,
      action: PayloadAction<{ data: IStoreCollection[] }>,
    ) => {
      state.collectionList = action.payload.data;
    },
    getStoreCollectionListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    getStoreShowcaseListRequest: (state, action) => {
      state.error = null;
      state.showcaseIndex = 0;
    },
    getStoreShowcaseListSuccess: (
      state,
      action: PayloadAction<{ data: IStoreShowcase[] }>,
    ) => {
      state.showcaseList = action.payload.data;
      state.showcaseIndex = 0;
    },
    getStoreShowcaseListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
      state.showcaseIndex = 0;
    },

    getStoreThemeListRequest: (state, action) => {
      state.error = null;
    },
    getStoreThemeListSuccess: (
      state,
      action: PayloadAction<{ data: IStoreTheme[] }>,
    ) => {
      state.themeList = action.payload.data;
    },
    getStoreThemeListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    setRequestStatus: (
      state,
      action: PayloadAction<{
        key: 'showcaseListStatus' | 'themeListStatus' | 'collectionListStatus';
        isRequested: boolean;
        isLoggedIn: boolean;
      }>,
    ) => {
      state.requestStatus[action.payload.key] = action.payload;
    },

    setShowcaseIndex: (state, action: PayloadAction<number>) => {
      state.showcaseIndex = action.payload;
    },
  },
});

export const {
  getStoreCollectionListRequest,
  getStoreCollectionListSuccess,
  getStoreCollectionListFailure,

  getStoreShowcaseListRequest,
  getStoreShowcaseListSuccess,
  getStoreShowcaseListFailure,

  getStoreThemeListRequest,
  getStoreThemeListSuccess,
  getStoreThemeListFailure,

  setRequestStatus,

  setShowcaseIndex,
} = camfitStoreHomeSlice.actions;

export default camfitStoreHomeSlice.reducer;

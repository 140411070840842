import {
  getStoreCategoryList,
  getStoreSearch,
  getStoreSearchAutoCompleteKeyword,
  getStoreSearchPopularKeyword,
  getStoreSearchRecommendKeyword,
} from 'api';
import { all, takeLatest } from 'redux-saga/effects';
import { createFetchAction } from 'store/sagas/createFetchAction';
import {
  storeCategoryListFailure,
  storeCategoryListRequest,
  storeCategoryListSuccess,
  storeSearchAutoCompleteKeywordFailure,
  storeSearchAutoCompleteKeywordRequest,
  storeSearchAutoCompleteKeywordSuccess,
  storeSearchFailure,
  storeSearchMoreRequest,
  storeSearchPopularKeywordFailure,
  storeSearchPopularKeywordRequest,
  storeSearchPopularKeywordSuccess,
  storeSearchRecommendKeywordFailure,
  storeSearchRecommendKeywordRequest,
  storeSearchRecommendKeywordSuccess,
  storeSearchRequest,
  storeSearchSuccess,
} from 'store/storeSearch/storeSearchReducer';

function* storeSearchRequestSaga() {
  yield takeLatest(
    [storeSearchRequest.type, storeSearchMoreRequest.type],
    createFetchAction(getStoreSearch, storeSearchSuccess, storeSearchFailure),
  );
}

function* storeSearchAutoCompleteKeywordRequestSaga() {
  yield takeLatest(
    storeSearchAutoCompleteKeywordRequest.type,
    createFetchAction(
      getStoreSearchAutoCompleteKeyword,
      storeSearchAutoCompleteKeywordSuccess,
      storeSearchAutoCompleteKeywordFailure,
    ),
  );
}

function* storeSearchRecommendKeywordRequestSaga() {
  yield takeLatest(
    storeSearchRecommendKeywordRequest.type,
    createFetchAction(
      getStoreSearchRecommendKeyword,
      storeSearchRecommendKeywordSuccess,
      storeSearchRecommendKeywordFailure,
    ),
  );
}

function* storeSearchPopularKeywordRequestSaga() {
  yield takeLatest(
    storeSearchPopularKeywordRequest.type,
    createFetchAction(
      getStoreSearchPopularKeyword,
      storeSearchPopularKeywordSuccess,
      storeSearchPopularKeywordFailure,
    ),
  );
}

function* storeCategoryListRequestSaga() {
  yield takeLatest(
    storeCategoryListRequest.type,
    createFetchAction(
      getStoreCategoryList,
      storeCategoryListSuccess,
      storeCategoryListFailure,
    ),
  );
}

export default function* storeSearchSaga() {
  yield all([
    storeSearchRequestSaga(),
    storeSearchAutoCompleteKeywordRequestSaga(),
    storeSearchRecommendKeywordRequestSaga(),
    storeSearchPopularKeywordRequestSaga(),
    storeCategoryListRequestSaga(),
  ]);
}

import { ErrorInfo } from 'react';
import axios from 'axios';
import { getEnvironment } from 'utils/common';
import { getServerURL } from 'server';

const baseApi = axios.create({
  baseURL: getServerURL(),
  headers: { 'Cache-Control': 'no-cache' },
});

export const postSlackNotiFrontendError = async (
  error: Error,
  info: ErrorInfo,
) => {
  const { name, message, stack } = error;
  const { componentStack } = info;

  // componentStackInfo가 너무 길어지는 경우 사용.
  // provider등을 제거하여 출력.
  const trimmedComponentStackInfo = componentStack
    .substring(0, componentStack.indexOf('at Switch'))
    .trim();

  const environment = getEnvironment().toUpperCase();

  // 브라우저 정보 수집
  const browserInfo = {
    userAgent: navigator?.userAgent || 'N/A',
    language: navigator?.language || 'N/A',
    platform: navigator?.platform || 'N/A',
    vendor: navigator?.vendor || 'N/A',
    screenSize: `${window?.screen?.width || 0}x${window?.screen?.height || 0}`,
    windowSize: `${window?.innerWidth || 0}x${window?.innerHeight || 0}`,
    connection: (navigator as any)?.connection?.effectiveType || 'unknown',
    memory: (performance as any)?.memory?.usedJSHeapSize
      ? `${Math.round(
          (performance as any)?.memory?.usedJSHeapSize / 1024 / 1024,
        )}MB`
      : 'unknown',
  };

  // 세션 정보 수집
  const sessionInfo = {
    timestamp: new Date().toISOString(),
    isLoggedIn: Boolean(localStorage?.getItem('jwt')),
    referrer: document?.referrer || 'Direct',
    currentPath: sessionStorage?.getItem('currentPath') || 'N/A',
    pageLoadTime: `${Math.round(performance?.now())}ms`,
  };

  const text = `
  🚨 *프론트엔드 : ${environment}* 🚨
  \`\`\`
  [브라우저 정보]
  User Agent: ${browserInfo.userAgent}
  Language: ${browserInfo.language}
  Platform: ${browserInfo.platform}
  Vendor: ${browserInfo.vendor}
  Screen Size: ${browserInfo.screenSize}
  Window Size: ${browserInfo.windowSize}
  Network: ${browserInfo.connection}
  Memory Usage: ${browserInfo.memory}

  [세션 정보]
  Timestamp: ${sessionInfo.timestamp}
  Is Logged In: ${sessionInfo.isLoggedIn}
  Referrer: ${sessionInfo.referrer}
  Current Path: ${sessionInfo.currentPath}
  Page Load Time: ${sessionInfo.pageLoadTime}

  [Error 정보]
  path: ${window?.location?.href}
  name: ${name}
  message: ${message}
  stack: ${stack}

  [컴포넌트 정보]
  componentStack: ${componentStack}
  \`\`\`
  `;

  try {
    await baseApi.post('v1/slack', {
      message: text,
    });
  } catch (error) {
    console.error('Slack 에러 메시지 전송 실패:', error);
  }
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer';
import { theme } from 'static/styles/theme';
import {
  IBiz,
  IBizCuration,
  IBizFeed,
  IBizFeedsResponse,
  IBizItem,
  IBizProfileStory,
  TBizShowcaseOrWallType,
} from 'types/biz';
import { IFeedDiary, TFeedCache } from 'types/feed';

interface Props {
  biz: IBiz | null;
  bizCurations: IBizCuration[] | null;

  bizCurationDetail: IBizCuration | null;
  bizCurationDetailPage: number;
  isBizCurationDetailEnd: boolean;
  isBizCurationDetailTouring: boolean;

  bizProfileStories: IBizProfileStory[];
  bizFeeds: IBizFeed[] | null;
  bizShowcaseOrWall: {
    type: TBizShowcaseOrWallType;
    data: any | any[];
  } | null;
  bizProfileChannels: {
    id: string;
    isDefault: boolean;
  }[];
  //   feedCampaigns: IFeedCampaign[];
  //   feedAdPostIds: Set<string>;
  bizFeedData: {
    name: string;
    data: IFeedDiary[];
    registerTimestamp: number;
  }[];
  bizFeedName: string | null;
  bizActiveTabIndex: number;
  bizPage: number[];
  bizPageSize: number;
  bizBgColor: string;
  bizTextColor: string;
  bizKeyColor: string;

  /** 피드 상세 진입 여부 */
  bizIsTouring: boolean;
  /** window 최상단에서 얼마만큼 스크롤 내렸는지 여부. */
  bizScrollTop: number;
  bizCacheMap: CellMeasurerCache;
  /** 마지막 노출된 게시물 index */
  bizScrollToIndex: number;
  /** header의 보여짐 상태 */
  //   headerState: '' | 'off';
  /** 리액션 lottie */
  bizLottieJsons: { [key: string]: any };
  /** 캠페인 전용 로띠 */
  //   campaignLottieJsons: { [key: string]: any };

  bizFeedCache: {
    name: string;
    cache: TFeedCache[];
  }[];

  /** 재측정 필요 여부 */
  bizNeedToMeasure: boolean;

  /**
   * 이하 브랜드 라운지
   */
  brandLoungeType: 'all' | 'following';
  allBrandList: any[];
  followingList: any[];
}

const initialState: Props = {
  biz: null,
  bizCurations: null,

  bizCurationDetail: null,
  bizCurationDetailPage: 1,
  isBizCurationDetailEnd: false,
  isBizCurationDetailTouring: false,

  bizProfileStories: [],
  bizFeeds: null,
  bizShowcaseOrWall: null,
  bizProfileChannels: [],

  //   feedCampaigns: [],ƒ√
  bizFeedData: [],
  //   feedAdPostIds: new Set(),
  bizFeedName: null,
  bizActiveTabIndex: 0,
  bizPage: [],
  bizPageSize: 30,

  bizBgColor: theme.newColors.white,
  bizTextColor: theme.newColors.black,
  bizKeyColor: theme.newColors.primary,

  bizIsTouring: false,
  bizScrollTop: 0,
  bizCacheMap: new CellMeasurerCache({
    fixedWidth: true,
  }),
  bizScrollToIndex: 0,
  /** header의 보여짐 여부 컨트롤 */
  //   headerState: '',
  bizLottieJsons: {},
  //   campaignLottieJsons: {},
  bizFeedCache: [],
  //   postScore: {
  //     feedId: '',
  //   },
  bizNeedToMeasure: false,

  brandLoungeType: 'all',
  allBrandList: [],
  followingList: [],
};

export const bizProfileSlice = createSlice({
  name: 'bizProfile',
  initialState,
  reducers: {
    setBiz: (state, action: PayloadAction<IBiz | null>) => {
      state.biz = action.payload;
    },

    setBizCurations: (state, action: PayloadAction<IBizCuration[]>) => {
      state.bizCurations = action.payload;
    },

    setBizCurationDetailPage: (state, action: PayloadAction<number>) => {
      state.bizCurationDetailPage = action.payload;
    },

    setBizCurationDetailEnd: (state, action: PayloadAction<boolean>) => {
      state.isBizCurationDetailEnd = action.payload;
    },

    setBizCurationDetailTouring: (state, action: PayloadAction<boolean>) => {
      state.isBizCurationDetailTouring = action.payload;
    },

    setBizCurationDetail: (state, action: PayloadAction<IBizCuration>) => {
      state.bizCurationDetail = action.payload;
    },

    appendBizCurationDetailItems: (
      state,
      action: PayloadAction<IBizItem[]>,
    ) => {
      if (state.bizCurationDetail) {
        state.bizCurationDetail.items = [
          ...state.bizCurationDetail.items,
          ...action.payload,
        ];
      }
    },

    setBizProfileStories: (
      state,
      action: PayloadAction<IBizProfileStory[]>,
    ) => {
      state.bizProfileStories = action.payload;
    },
    setBizFeeds: (state, action: PayloadAction<IBizFeed[]>) => {
      state.bizFeeds = action.payload;
    },
    setBizShowcaseOrWall: (
      state,
      action: PayloadAction<{
        type: TBizShowcaseOrWallType;
        data: any | any[];
      }>,
    ) => {
      state.bizShowcaseOrWall = action.payload;
    },
    setBizProfileChannels: (
      state,
      action: PayloadAction<{ id: string; isDefault: boolean }[]>,
    ) => {
      state.bizProfileChannels = action.payload;
    },
    // setFeedCampaigns: (state, action: PayloadAction<IFeedCampaign[]>) => {
    //   state.feedCampaigns = action.payload;
    // },
    // setCampaignLottieJsons: (
    //   state,
    //   action: PayloadAction<{ id: string; jsonData: string }>,
    // ) => {
    //   const { id, jsonData } = action.payload;
    //   state.campaignLottieJsons = {
    //     ...state.campaignLottieJsons,
    //     [id]: jsonData,
    //   };
    // },
    setBizFeedName: (state, action: PayloadAction<string>) => {
      state.bizFeedName = action.payload;
    },
    setBizActiceIndexTab: (state, action: PayloadAction<number>) => {
      state.bizActiveTabIndex = action.payload;
    },
    setBizPage: (
      state,
      action: PayloadAction<{ bizActiveTabIndex: number; value: number }>,
    ) => {
      const { bizActiveTabIndex, value } = action.payload;

      state.bizPage[bizActiveTabIndex] = value;
    },

    setBizBgColor: (state, action: PayloadAction<string>) => {
      state.bizBgColor = action.payload;
    },

    setBizTextColor: (state, action: PayloadAction<string>) => {
      state.bizTextColor = action.payload;
    },

    setBizKeyColor: (state, action: PayloadAction<string>) => {
      state.bizKeyColor = action.payload;
    },

    setBizScrollTop: (state, action: PayloadAction<number>) => {
      state.bizScrollTop = action.payload;
    },
    setBizCacheMap: (state, action: PayloadAction<CellMeasurerCache>) => {
      state.bizCacheMap = action.payload;
    },
    setBizScrollToIndex: (state, action: PayloadAction<number>) => {
      state.bizScrollToIndex = action.payload;
    },
    onBizTouring: state => {
      state.bizIsTouring = true;
    },
    offBizTouring: state => {
      state.bizIsTouring = false;
    },

    // setHeaderState: (state, action: PayloadAction<'' | 'off'>) => {
    //   state.headerState = action.payload;
    // },

    initBizFeedData: (state, action: PayloadAction<string[]>) => {
      const feedNames = action.payload;

      state.bizPage = new Array(feedNames.length).fill(1);

      state.bizFeedData = feedNames.map(feedName => {
        return {
          name: feedName,
          data: [],
          registerTimestamp: 0,
        };
      });

      state.bizFeedCache = feedNames.map(feedName => {
        return {
          name: feedName,
          cache: [],
        };
      });
    },

    setBizFirstFeedData: (
      state,
      action: PayloadAction<{
        name: string;
        data: IFeedDiary[];
      }>,
    ) => {
      const { name, data } = action.payload;

      const targetIndex = state.bizFeedData.findIndex(
        feed => feed.name === name,
      );
      state.bizPage[targetIndex] = 1;

      state.bizFeedData = state.bizFeedData.map(feed =>
        feed.name !== name
          ? feed
          : {
              name,
              data,
              registerTimestamp: new Date().getTime(),
            },
      );

      state.bizFeedCache = state.bizFeedCache.map(cache =>
        cache.name !== name
          ? cache
          : {
              name,
              cache: data.map(el => {
                if (name === 'Review') {
                  return {
                    isMoreBtnClicked: false,
                    isCampInfoShow: false,
                  };
                }
                return {
                  isMoreBtnClicked: false,
                };
              }),
            },
      );
    },

    changeBizFeedData: (
      state,
      action: PayloadAction<{ name: string; data: IFeedDiary[] }>,
    ) => {
      const { name, data } = action.payload;

      state.bizFeedData = state.bizFeedData.map(feed =>
        feed.name !== name
          ? feed
          : { name, data, registerTimestamp: new Date().getTime() },
      );
    },

    appendBizFeedData: (
      state,
      action: PayloadAction<{ name: string; data: IFeedDiary[] }>,
    ) => {
      const { name, data } = action.payload;

      state.bizFeedData = state.bizFeedData.map(feed =>
        feed.name !== name
          ? feed
          : {
              name,
              data: [...feed.data, ...data],
              registerTimestamp: new Date().getTime(),
            },
      );

      const appendCache =
        name === 'Review'
          ? new Array(data.length).fill({
              isMoreBtnClicked: false,
              isCampInfoShow: false,
            })
          : new Array(data.length).fill({ isMoreBtnClicked: false });

      state.bizFeedCache = state.bizFeedCache.map(cache =>
        cache.name !== name
          ? cache
          : {
              name,
              cache: [...cache.cache, ...appendCache],
            },
      );
    },

    // appendFeedAdPostId: (state, action: PayloadAction<string[]>) => {
    //   const feedIds = action.payload;
    //   feedIds.forEach(id => state.feedAdPostIds.add(id));
    // },

    resetBizFeedDataAll: state => {
      state.biz = null;
      state.bizCurations = null;
      state.bizCurationDetail = null;
      state.bizProfileStories = [];
      state.bizFeeds = null;
      state.bizShowcaseOrWall = null;
      state.bizProfileChannels = [];
      state.bizFeedData = [];
      state.bizFeedName = null;
      state.bizActiveTabIndex = 0;
      state.bizPage = [];
      state.bizPageSize = 30;
      state.bizBgColor = theme.newColors.white;
      state.bizTextColor = theme.newColors.black;
      state.bizKeyColor = theme.newColors.primary;

      state.bizIsTouring = false;
      state.bizScrollTop = 0;
      state.bizCacheMap = new CellMeasurerCache({
        fixedWidth: true,
      });
      state.bizScrollToIndex = 0;
      //   state.headerState = '';
      //   state.feedAdPostIds = new Set();
    },

    /**
     * 로그인 시 새로운 데이터를 받아오기 위해 registerTimestamp reset 처리
     * 참조 슬랙
     * @description https://nexteditionworkspace.slack.com/archives/C06QPRFSBRD/p1724461843839659
     */
    resetBizRegisterTimestamp: state => {
      state.bizFeedData = state.bizFeedData.map(feed => {
        return {
          ...feed,
          registerTimestamp: 0,
        };
      });
    },

    setBizLottieJsons: (
      state,
      action: PayloadAction<{ id: string; jsonData: string }>,
    ) => {
      const { id, jsonData } = action.payload;
      state.bizLottieJsons = { ...state.bizLottieJsons, [id]: jsonData };
    },

    changeBizFeedCache: (
      state,
      action: PayloadAction<{
        name: string;
        data: TFeedCache[];
      }>,
    ) => {
      const { name, data } = action.payload;
      state.bizFeedCache = state.bizFeedCache.map(cache =>
        cache.name !== name
          ? cache
          : {
              name,
              cache: data,
            },
      );
    },

    setBizNeedToMeasure: (state, action: PayloadAction<boolean>) => {
      state.bizNeedToMeasure = action.payload;
    },

    // initPostScore: (state, action: PayloadAction<string>) => {
    //   state.postScore = {
    //     feedId: action.payload,
    //   };
    // },

    // appendPostScore: (
    //   state,
    //   action: PayloadAction<{
    //     key: keyof Omit<IFeedPostScore, 'feedId'>;
    //     postId: string;
    //     postPoolId?: string;
    //   }>,
    // ) => {
    //   const key = action.payload.key;
    //   const postId = action.payload.postId;
    //   const postPoolId = action.payload.postPoolId;

    //   const payload = postPoolId
    //     ? { post: postId, postPoolId }
    //     : { post: postId };

    //   const prevValue = state.postScore[key];

    //   if (prevValue) {
    //     prevValue.push(payload);
    //   } else {
    //     state.postScore[key] = [payload];
    //   }
    // },

    setNeedToSendPostScore: (state, action: PayloadAction<string>) => {},

    setBrandLoungeType: (state, action: PayloadAction<'all' | 'following'>) => {
      state.brandLoungeType = action.payload;
    },

    setAllBrandList: (state, action: PayloadAction<any[]>) => {
      state.allBrandList = action.payload;
    },

    setFollowingList: (state, action: PayloadAction<any[]>) => {
      state.followingList = action.payload;
    },
  },
});

export const {
  setBiz,
  setBizCurations,

  setBizCurationDetailPage,
  setBizCurationDetailEnd,
  setBizCurationDetailTouring,

  setBizCurationDetail,
  appendBizCurationDetailItems,
  setBizProfileStories,
  setBizFeeds,
  setBizShowcaseOrWall,
  setBizProfileChannels,
  //   setFeedCampaigns,
  setBizFeedName,
  setBizActiceIndexTab,
  setBizPage,

  setBizBgColor,
  setBizTextColor,
  setBizKeyColor,

  onBizTouring,
  offBizTouring,
  setBizScrollTop,
  setBizCacheMap,
  setBizScrollToIndex,
  //   setHeaderState,
  initBizFeedData,
  setBizFirstFeedData,
  changeBizFeedData,
  appendBizFeedData,
  resetBizFeedDataAll,
  resetBizRegisterTimestamp,
  setBizLottieJsons,
  //   setCampaignLottieJsons,
  changeBizFeedCache,
  setBizNeedToMeasure,
  //   initPostScore,
  //   appendPostScore,
  //   appendFeedAdPostId,
  setNeedToSendPostScore,

  setBrandLoungeType,
  setAllBrandList,
  setFollowingList,
} = bizProfileSlice.actions;

export default bizProfileSlice.reducer;

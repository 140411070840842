import { TCouponPrice, ISelectiveCoupon, ICalculateLog } from '@types';
import { ICouponTag } from 'components/organisms/BigCoupon';
import { BookingType } from 'store/reducers/longStay';

const oneDay = 86400000;

export const getCouponTag = (
  target: 'camp' | 'all' | 'zone' | 'zoneType' | string,
) => {
  if (target === 'camp' || target === 'all') {
    return {
      text: '전체 캠핑존',
      color: 'blue',
    } as ICouponTag;
  }

  return {
    text: '일부 캠핑존',
    color: 'violet',
  } as ICouponTag;
};

const getDateDiff = (date1TimeStamp: number, date2TimeStamp: number) => {
  const date1 = new Date(date1TimeStamp);
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const date2 = new Date(date2TimeStamp);
  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  const newDate1 = new Date(year1, month1, day1, 0, 0, 0);
  const newDate2 = new Date(year2, month2, day2, 0, 0, 0);
  const diffDate = newDate1.getTime() - newDate2.getTime();

  return diffDate / oneDay;
};

export const getDday = (expireTimestamp: number) => {
  const todayTimeStamp = new Date().getTime();
  const days = getDateDiff(expireTimestamp, todayTimeStamp);
  const isTodayExpiredDay = days === 0;

  if (isTodayExpiredDay) {
    return 'day';
  }

  return days;
};

export const checkIsDownloadable = (
  isIssued: boolean,
  maxNumToIssueByUser: number,
  numOfUserIssued: number,
  maxNumToDailyIssue: number,
  numOfDailyIssued: number,
  maxNumToIssue: number,
  numOfIssued: number,
) => {
  if (
    isIssued ||
    numOfUserIssued >= maxNumToIssueByUser ||
    numOfIssued >= maxNumToIssue ||
    numOfDailyIssued >= maxNumToDailyIssue
  ) {
    return false;
  }

  return true;
};

export const getRestrictionReason = (
  maxNumToIssueByUser: number,
  numOfUserIssued: number,
  maxNumToDailyIssue: number,
  numOfDailyIssued: number,
  maxNumToIssue: number,
  numOfIssued: number,
) => {
  if (numOfUserIssued >= maxNumToIssueByUser) {
    return 'user';
  }

  if (numOfIssued >= maxNumToIssue) {
    return 'total';
  }

  if (numOfDailyIssued >= maxNumToDailyIssue) {
    return 'daily';
  }

  return '';
};

export const getDiscountPhrase = (bestCoupon: TCouponPrice) => {
  const { isDiscountPrice, discountPrice, discountPercent } = bestCoupon;

  switch (isDiscountPrice) {
    case true:
      return `${discountPrice.toLocaleString()}원`;

    case false:
      return `${discountPercent}%`;

    default:
      return '';
  }
};

export const getMinOrHourOrDays = (minutes: number) => {
  const hours = minutes / 60;
  if (Math.floor(minutes) < 1) {
    return `발급 후 ${minutes}분 이내`;
  }
  if (hours < 24) {
    return `발급 후 ${Math.round(hours)}시간 이내`;
  }
  if (hours >= 24) {
    return `발급 후 ${Math.round(hours / 24)}일 이내`;
  }
};

export const getDiscountedPrice = (
  basicAccommodationCharge: number,
  coupon?: ISelectiveCoupon,
) => {
  if (!coupon) return 0;
  const discountPrice = getDiscountPrice(basicAccommodationCharge, coupon);

  const discountedPrice = basicAccommodationCharge - discountPrice;
  return discountedPrice;
};

export const getDiscountPrice = (
  basicAccommodationCharge: number,
  coupon?: ISelectiveCoupon,
  bookingType?: BookingType,
  optionCharges?: {
    name: string;
    optionFilterName: string;
    charge: number;
    optionChargeType: 'EACH_DAY' | 'ONE_TIME';
  }[],
  calculateLogs?: ICalculateLog[],
) => {
  if (bookingType === 'longStay') return 0;
  if (!coupon) return 0;

  let discountPrice = coupon.isDiscountPrice
    ? coupon.discountPrice
    : Math.round((basicAccommodationCharge / 100) * coupon.discountPercent);

  if (discountPrice > basicAccommodationCharge) {
    discountPrice = basicAccommodationCharge;
  }

  if (coupon.isOptionSettlementAvailable) {
    if (optionCharges) {
      optionCharges?.forEach((option: any) => {
        discountPrice += option.charge * (coupon.discountPercent / 100);
      });
    } else {
      calculateLogs?.forEach((log: ICalculateLog) => {
        if (log.type === 'optionCoupon') {
          discountPrice -= log.charge;
        }
      });
    }
  }

  if (
    !coupon.isDiscountPrice &&
    typeof coupon.maxDiscountAmount === 'number' &&
    coupon.maxDiscountAmount > 0
  ) {
    if (discountPrice > coupon.maxDiscountAmount) {
      discountPrice = coupon.maxDiscountAmount;
    }
  }

  return discountPrice;
};

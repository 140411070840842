import { PayloadAction } from '@reduxjs/toolkit';
import { IResponse } from '@types';
import { customHistory } from 'App';
import { getSocialSelfAuthenticationResult } from 'api';
import { postFeedbackLog } from 'hooks/useGetTrackId';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from 'store/reducers';
import { getUserTrackingInfoRequest } from 'store/reducers/algoliaSearch';
import { registerShopbyRequest } from 'store/reducers/register';
import {
  setSocialLoginProvider,
  setSocialLoginProviderEmail,
} from 'store/reducers/socialLogin';
import {
  socialLoginSelfAuthenticationRequest,
  socialLoginSelfAuthenticationResultFailure,
  socialLoginSelfAuthenticationResultRequest,
  socialLoginSelfAuthenticationResultSuccess,
} from 'store/reducers/socialLoginSelfAuthentication';
import { createFetchAction } from 'store/sagas/createFetchAction';
import { failure } from 'store/sagas/failure';
import {
  ISocialLoginSelfAuthenticationRequestPayload,
  ISocialLoginSelfAuthenticationResultResponsePayload,
} from 'store/types';
import bootPaySelfAuthentication from 'utils/bootPaySelfAuthentication';
import { getSourcePath, removeSourcePath } from 'utils/sessionStorageUtils';
import setReservationInfoState from 'utils/setReservationInfoState';

const queryString = require('query-string');

interface LocationState {
  isAuthorized?: boolean;
  from: { pathname: string };
}

function* getSocialSelfAuthenticationSaga() {
  yield takeLatest(
    socialLoginSelfAuthenticationRequest.type,
    function* process(
      action: PayloadAction<ISocialLoginSelfAuthenticationRequestPayload>,
    ) {
      const { payload } = action;
      yield call(bootPaySelfAuthentication, payload);
    },
  );
}

function* getSocialSelfAuthticationResultSaga() {
  yield takeLatest(
    socialLoginSelfAuthenticationResultRequest.type,
    createFetchAction(
      getSocialSelfAuthenticationResult,
      socialLoginSelfAuthenticationResultSuccess,
      socialLoginSelfAuthenticationResultFailure,
      function* success(
        res: ISocialLoginSelfAuthenticationResultResponsePayload,
      ) {
        const {
          certificationId,
          isNewUser,
          providerEmail,
          nextRoute,
          jwt,
          user,
          marketingAgreement,
        } = yield res;
        if (certificationId && isNewUser && nextRoute) {
          if (providerEmail) {
            yield put(setSocialLoginProviderEmail(providerEmail));
          }

          customHistory.push(nextRoute); // '/sign-up/terms'

          return;
        }

        const { email, nickname, thumbnail, brief, role } = user;
        if (role.name !== 'Authenticated') {
          /**
           * 비즈 프로필 아이디는 제외.
           */
          if (!['biz', 'bizManager'].includes(user.role.name)) {
            alert('캠지기센터 아이디는 사용하실 수 없습니다');
            return;
          }
        }

        // NOTE: 로그인 성공 시점에 샵바이 토큰이 없다면, 샵바이 회원가입
        const shopbyAccessToken: string | null | undefined =
          localStorage.getItem('shopbyAccessToken');

        if (!shopbyAccessToken) {
          yield put(registerShopbyRequest());
        }

        localStorage.setItem('jwt', jwt);
        localStorage.setItem('email', email);
        localStorage.setItem('nickname', nickname);
        brief && localStorage.setItem('brief', brief);

        if (jwt && !!window.BootpayRNWebView) {
          window.BootpayRNWebView.postMessage(`jwt:${jwt}`);
          window.BootpayRNWebView.postMessage(`refreshAppToken:${jwt}`);
        }

        yield put(getUserTrackingInfoRequest());

        if (thumbnail && thumbnail.url) {
          localStorage.setItem('thumbnail', thumbnail.url);
        }

        if (typeof marketingAgreement === 'boolean') {
          localStorage.setItem(
            'marketingAgreement',
            String(marketingAgreement),
          );
        }

        const visitedPages: string[] = yield select(
          (state: RootState) => state.feedbackLogReducer.visitedPages,
        );

        postFeedbackLog({
          action: 'click',
          target: 'login',
          targetParam: {
            userId: user.id,
            nickname,
            function: 'getSocialSelfAuthticationResultSaga',
            jwt,
            savedJwt: localStorage.getItem('jwt'),
            isSame: jwt === localStorage.getItem('jwt'),
          },
          visitedPages,
        });

        const locationState: LocationState = yield customHistory.location.state;

        const isGoingToReservation = JSON.parse(
          sessionStorage.getItem('isGoingToReservation') || 'null',
        );

        const sourcePath = getSourcePath();
        const query = queryString.parse(customHistory.location.search);
        const { id: bookingId } = query;

        if (isGoingToReservation) {
          yield setReservationInfoState();
          sessionStorage.removeItem('isGoingToReservation');
          // 로그인 하지 않은 상태로 예약 하는 경우
          customHistory.push('/reservation');
        } else if (bookingId) {
          // 로그인 하지 않은 상태에서 예약페이지 접근시 쿼리로 bookignId 전달
          customHistory.push(`/reservation/result/${bookingId}`);
        } else if (locationState?.isAuthorized !== undefined) {
          // 로그인 하지 않은 상태로 예약 하는 경우
          customHistory.push('/reservation');
        } else if (locationState?.from.pathname) {
          // 로그인 하지 않은 상태로 <PrivateRoute/>로 점근하는 경우
          customHistory.push(locationState?.from.pathname);
        } else if (sourcePath) {
          // sourcePath 설정 되어 있는 경우
          yield removeSourcePath();
          yield customHistory.push(sourcePath);
        } else {
          customHistory.push('/');
        }
      },
      function* fail(
        res:
          | Error
          | IResponse
          | ISocialLoginSelfAuthenticationResultResponsePayload,
      ) {
        const {
          nextRoute,
          provider,
          email: providerEmail, // 소셜에서 받아온 이메일
        } = yield res;

        if (nextRoute) {
          if (provider) {
            yield put(setSocialLoginProvider(provider));
          }

          if (providerEmail) {
            yield put(setSocialLoginProviderEmail(providerEmail));
          }

          customHistory.push(nextRoute);

          return;
        }

        if (!localStorage.jwt) {
          if (res && res.message && typeof res.message === 'string') {
            yield alert(`${res.message}`);
            customHistory.push('/login');
          } else {
            yield alert('로그인 정보가 올바르지 않습니다.');
          }
          return;
        }

        yield failure(res as Error | IResponse);
      },
    ),
  );
}

export default function* socialLoginSelfAuthenticationSaga() {
  yield all([
    getSocialSelfAuthenticationSaga(),
    getSocialSelfAuthticationResultSaga(),
  ]);
}

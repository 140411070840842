import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IBizMessage } from 'types/biz';

interface Props {
  userInfo: any;
  isFollowing: boolean;
  isChannelBlocked: boolean;
  isChannelAlarm: boolean;
  bizProfile: any;
  bizChannel: any;
  isChannelLoading: boolean;
  needToGoBottom: boolean;

  channelPage: number;
  isChannelInitLoading: boolean;
  isChannelTouring: boolean;
  isMessageEnd: boolean;
  groupeByedMessageArray: {
    startOfDayTimestamp: number;
    messages: IBizMessage[];
  }[];

  channelScrollY: number;
}

const initialState: Props = {
  userInfo: null,
  isFollowing: true,
  isChannelBlocked: false,
  isChannelAlarm: false,
  bizProfile: null,
  bizChannel: null,
  isChannelLoading: false,
  needToGoBottom: true,

  channelPage: 1,
  isChannelInitLoading: true,
  isChannelTouring: false,
  isMessageEnd: false,
  groupeByedMessageArray: [],

  channelScrollY: 0,
};

export const bizChannelSlice = createSlice({
  name: 'bizChannel',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload;
    },
    setIsFollowing: (state, action: PayloadAction<boolean>) => {
      state.isFollowing = action.payload;
    },
    setIsChannelBlocked: (state, action: PayloadAction<boolean>) => {
      state.isChannelBlocked = action.payload;
    },
    setIsChannelAlarm: (state, action: PayloadAction<boolean>) => {
      state.isChannelAlarm = action.payload;
    },
    setBizProfile: (state, action: PayloadAction<any>) => {
      state.bizProfile = action.payload;
    },
    setBizChannel: (state, action: PayloadAction<any>) => {
      state.bizChannel = action.payload;
    },
    setIsChannelLoading: (state, action: PayloadAction<boolean>) => {
      state.isChannelLoading = action.payload;
    },
    setNeedToGoBottom: (state, action: PayloadAction<boolean>) => {
      state.needToGoBottom = action.payload;
    },
    setChannelPage: (state, action: PayloadAction<number>) => {
      state.channelPage = action.payload;
    },
    setIsChannelInitLoading: (state, action: PayloadAction<boolean>) => {
      state.isChannelInitLoading = action.payload;
    },
    setIsChannelTouring: (state, action: PayloadAction<boolean>) => {
      state.isChannelTouring = action.payload;
    },
    setIsMessageEnd: (state, action: PayloadAction<boolean>) => {
      state.isMessageEnd = action.payload;
    },
    setGroupeByedMessageArray: (
      state,
      action: PayloadAction<
        { startOfDayTimestamp: number; messages: IBizMessage[] }[]
      >,
    ) => {
      state.groupeByedMessageArray = action.payload;
    },
    appendGroupeByedMessageArray: (
      state,
      action: PayloadAction<
        { startOfDayTimestamp: number; messages: IBizMessage[] }[]
      >,
    ) => {
      state.groupeByedMessageArray = [
        ...state.groupeByedMessageArray,
        ...action.payload,
      ];
    },
    setChannelScrollY: (state, action: PayloadAction<number>) => {
      state.channelScrollY = action.payload;
    },

    resetAllBizChannelData: state => {
      state.userInfo = null;
      state.isFollowing = true;
      state.isChannelBlocked = false;
      state.isChannelAlarm = false;
      state.bizProfile = null;
      state.bizChannel = null;
      state.isChannelLoading = false;
      state.needToGoBottom = true;
      state.channelPage = 1;
      state.isChannelInitLoading = true;
      state.isChannelTouring = false;
      state.isMessageEnd = false;
      state.groupeByedMessageArray = [];
      state.channelScrollY = 0;
    },
  },
});

export const {
  setUserInfo,
  setIsFollowing,
  setIsChannelBlocked,
  setIsChannelAlarm,
  setBizProfile,
  setBizChannel,
  setIsChannelLoading,
  setNeedToGoBottom,
  setChannelPage,
  setIsChannelInitLoading,
  setIsChannelTouring,
  setIsMessageEnd,
  setGroupeByedMessageArray,
  appendGroupeByedMessageArray,
  setChannelScrollY,
  resetAllBizChannelData,
} = bizChannelSlice.actions;

export default bizChannelSlice.reducer;

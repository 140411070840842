import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICamfitMedia, IItemDetail } from '@types';
import {
  IErrorPayloadWithKey,
  IStoreStoryCollectionDetailState,
} from 'store/types';
import {
  IStoreStoryCollectionDetail,
  TCollectionSubType,
  TCollectionType,
} from 'types/store/storeTypes';

const initialState: IStoreStoryCollectionDetailState = {
  collection: {
    id: '',
    title: '',
    brief: '',
    collectionType: 'storyFocused' as TCollectionType,
    collectionSubType: 'brandMagazine' as TCollectionSubType,
    detailThumbnail: {} as ICamfitMedia,
    stories: [],
  },
  storyItems: {},
  error: null,
};

export const storeStoryCollectionDetailSlice = createSlice({
  name: 'storeStoryCollectionDetail',
  initialState,
  reducers: {
    getStoreStoryCollectionDetailRequest: (
      state,
      action: PayloadAction<{
        collectionId: string;
      }>,
    ) => {
      state.error = null;
    },
    getStoreStoryCollectionDetailSuccess: (
      state,
      action: PayloadAction<{
        data: IStoreStoryCollectionDetail;
      }>,
    ) => {
      const filteredStories = action.payload.data.stories.filter(
        story => story.brand,
      );

      state.collection = {
        ...action.payload.data,
        stories: filteredStories,
      };
    },
    getStoreStoryCollectionDetailFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    getStoreStoryCollectionDetailItemRequest: (
      state,
      action: PayloadAction<{
        key: string;
        skip: number;
        limit: number;
      }>,
    ) => {
      state.storyItems[action.payload.key] = {
        paging: { skip: 0, limit: 10, hasNext: true },
        items: [],
        error: null,
      };
      state.error = null;
    },
    getStoreStoryCollectionDetailItemMoreRequest: (
      state,
      action: PayloadAction<{
        key: string;
        skip: number;
        limit: number;
      }>,
    ) => {
      state.storyItems[action.payload.key].paging.skip = action.payload.skip;
    },
    getStoreStoryCollectionDetailItemSuccess: (
      state,
      action: PayloadAction<{
        key: string;
        data: {
          data: {
            key: string;
            items: IItemDetail[];
            hasNext: boolean;
          };
        };
      }>,
    ) => {
      const { key, data } = action.payload;
      const { items, hasNext } = data.data;

      state.storyItems[key].items = [...state.storyItems[key].items, ...items];
      state.storyItems[key].paging.hasNext = hasNext;
    },
    getStoreStoryCollectionDetailItemFailure: (
      state,
      action: PayloadAction<IErrorPayloadWithKey>,
    ) => {
      const { key, error } = action.payload;
      state.storyItems[key].error = error;
    },
  },
});

export const {
  getStoreStoryCollectionDetailRequest,
  getStoreStoryCollectionDetailSuccess,
  getStoreStoryCollectionDetailFailure,
  getStoreStoryCollectionDetailItemRequest,
  getStoreStoryCollectionDetailItemMoreRequest,
  getStoreStoryCollectionDetailItemSuccess,
  getStoreStoryCollectionDetailItemFailure,
} = storeStoryCollectionDetailSlice.actions;

export default storeStoryCollectionDetailSlice.reducer;

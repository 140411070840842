import React, { ElementType, forwardRef, Ref, useEffect } from 'react';
import {
  TextWeight,
  TextVariant,
  NumberTextVariant,
} from 'static/styles/style';
import { theme } from 'static/styles/theme';
import tStyled, { CSSObject, CSSProp } from 'styled-components';

export interface TypographyProps {
  children: string | React.ReactNode;
  variant: TextVariant | NumberTextVariant;
  weight: TextWeight;
  tagName?: ElementType;
  color?: string;
  background?: keyof typeof theme.newColors;
  textAlign?:
    | 'center'
    | 'left'
    | 'right'
    | 'unset'
    | 'start'
    | 'end'
    | 'justify'
    | 'justify-all'
    | 'match-parent';
  isVerticalAlign?: boolean;
  cssObject?: CSSProp;
  className?: string;
  onClick?: (e: any) => void;
  id?: string;
}

/**
 * @param {string | React.ReactNode} children
 * @param {ElementType} tagName - html tag name
 * @param {string} color - 폰트 색상
 * @param {string} background - 배경 색상
 * @param {string} weight - 디자인 시스템에서 사용되는 스타일 카테고리. 해당 파라미터에 따라 font-weight 이 정의 됨.
 * @param {string} textAlign - 텍스트 정렬
 * @param {string} isVerticalAlign - 텍스트 세로 미세 정렬을 위해 margin-bottom: -0.15rem을 추가 하였음
 * @param {string} variant - 디자인 시스템에서 사용되는 타이포그라피 이름 카멜케이스식 표기 (예: LargeTitle 등). 해당 파라미터에 따라 font-size, line-height, letter-spacing 이 정의됨.
 * @param {CSSProp} cssObject - tStyled-components 의 css object. 해당 파라미터에 따라 추가적인 커스텀 css를 적용시킬 수 있음.
 */

function Typography(
  {
    children,
    tagName = 'p',
    color = 'black',
    background,
    weight = 'regular',
    textAlign,
    isVerticalAlign = false,
    variant,
    cssObject,
    className,
    onClick,
    id,
  }: TypographyProps,
  ref: Ref<any>,
) {
  const getClasses = () => {
    let classes = `typography-${variant} typography-${weight}`;
    classes = className ? `${classes} ${className}` : classes;
    return classes;
  };

  const classes = getClasses();

  return (
    <TypographyComponent
      id={id}
      as={tagName}
      tColor={color}
      tBackground={background}
      tAlign={textAlign}
      isVerticalAlign={isVerticalAlign}
      css={cssObject}
      className={classes}
      ref={ref}
      onClick={onClick}
    >
      {children}
    </TypographyComponent>
  );
}

interface ITypographyStyle {
  tColor: keyof typeof theme.newColors;
  tBackground?: keyof typeof theme.newColors;
  tAlign?: string;
  isVerticalAlign: boolean;
  css?: CSSObject;
}

const TypographyComponent = tStyled.p<ITypographyStyle>`
  color: ${({ theme, tColor }) => theme.newColors[tColor]};
  background: ${({ theme, tBackground }) =>
    tBackground && theme.newColors[tBackground]};
  text-align: ${({ tAlign }) => tAlign && tAlign};
  margin-bottom: ${({ isVerticalAlign }) =>
    isVerticalAlign ? '-0.2rem' : '0'};
  ${({ css }) => css && css}
`;

export default forwardRef(Typography);

import { ReactChildren, ReactNode } from 'react';
import styled from 'styled-components';
import ModalContainer from 'widgets/ModalContainer';
import { RowContainer } from 'widgets/FlexBox';
import BasicButton from 'components/atoms/BasicButton';
import { theme } from 'static/styles/theme';
import SpinLoader from 'widgets/SpinLoader';
import { CancelIcon } from 'static/newIcons/outline/regular';

export interface ModalWithButtonsProps {
  headMessageIcon?: ReactNode;
  headMessage?: string | ReactNode;
  primaryBtnText: string;
  primaryBtnColor?: string;
  primaryBtnTextColor?: string;
  primaryBtnType?: 'contained' | 'outlined' | 'ghost' | 'fullBleed';
  secondaryBtnText?: string;
  secondaryBtnColor?: string;
  secondaryBtnTextColor?: string;
  secondaryBtnType?: 'contained' | 'outlined' | 'ghost' | 'fullBleed';
  onClickPrimary: (e?: any) => void;
  onClickSecondary?: () => void;
  onClose?: () => void;
  loading?: boolean;
  preventOutsideClose?: boolean;
  image?: string;
  width?: string;
  isCloseVisible?: boolean;
  children: ReactNode | ReactChildren;
  inModal?: boolean;
}

const ModalWithButtons = ({
  headMessageIcon,
  headMessage,
  primaryBtnColor = theme.newColors.primary,
  primaryBtnText = '확인',
  primaryBtnTextColor = theme.newColors.white,
  primaryBtnType = 'contained',
  secondaryBtnColor = theme.newColors.lightGrey1,
  secondaryBtnText = '취소',
  secondaryBtnTextColor = theme.newColors.grey4,
  secondaryBtnType = 'contained',
  onClickPrimary,
  onClickSecondary,
  onClose,
  loading = false,
  preventOutsideClose = false,
  image,
  width,
  isCloseVisible = false,
  children,
  inModal = false,
}: ModalWithButtonsProps) => {
  return (
    <ModalContainer
      onClick={!preventOutsideClose ? onClose : undefined}
      bg="rgb(14, 14, 14, 0.6)"
      inModal={inModal}
    >
      <ModalBox onClick={e => e.stopPropagation()} width={width}>
        {loading ? (
          <div style={{ padding: '60px 24px 60px 24px' }}>
            <SpinLoader scroll />
          </div>
        ) : (
          <>
            {isCloseVisible && (
              <CancelIcon
                className="close-icon"
                onClick={onClose}
                width="24"
                height="24"
                fill="black"
              />
            )}
            {image && <img alt="modal_image" src={image} />}
            {headMessageIcon && (
              <HeadMessageIcon>{headMessageIcon}</HeadMessageIcon>
            )}
            {headMessage && <HeadMessage>{headMessage}</HeadMessage>}
            {children && <ContentMessage>{children}</ContentMessage>}

            <ButtonSection>
              {onClickSecondary && (
                <BasicButton
                  size="full"
                  color={secondaryBtnTextColor}
                  background={secondaryBtnColor}
                  onClick={onClickSecondary}
                  type={secondaryBtnType}
                  height="50px"
                >
                  {secondaryBtnText}
                </BasicButton>
              )}
              <BasicButton
                size="full"
                color={primaryBtnTextColor}
                background={primaryBtnColor}
                onClick={onClickPrimary}
                type={primaryBtnType}
                height="50px"
              >
                {primaryBtnText}
              </BasicButton>
            </ButtonSection>
          </>
        )}
      </ModalBox>
    </ModalContainer>
  );
};

export default ModalWithButtons;

const ModalBox = styled.div<{ width?: string }>`
  position: relative;
  width: ${({ width }) => width || '298px'};
  background: ${({ theme }) => theme.colors.bw100};
  border-radius: 16px;
  padding: 19.2px;

  .close-icon {
    position: absolute;
    cursor: pointer;
    top: 14px;
    right: 14px;
  }
  img {
    border-radius: 16px 16px 0 0;
    margin-top: -19.2px;
    margin-left: -19.2px;
    width: calc(100% + 38.4px);
    height: 100%;
  }
`;

const HeadMessageIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
`;

const HeadMessage = styled.p`
  white-space: pre-wrap;
  text-align: center;
  margin: 4px 0 10px 0;
  font-size: ${({ theme }) => theme.fontSizes.h20};
  font-weight: 700;
  color: ${({ theme }) => theme.newColors.black};
`;

const ContentMessage = styled.div`
  margin: 0 0 20px 0;
  font-size: ${({ theme }) => theme.fontSizes.b14};
  font-weight: 400;
  text-align: center;
  line-height: 25px;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.newColors.grey4};
  letter-spacing: -0.32px;
`;

const ButtonSection = styled(RowContainer)`
  gap: 12px;
`;

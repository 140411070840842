import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICamfitMedia } from '@types';
import { AxiosError } from 'axios';
import { IStoreCollectionDetailState } from 'store/types';
import {
  IStoreCollectionDetail,
  TCollectionSubType,
  TCollectionType,
} from 'types/store/storeTypes';

const initialState: IStoreCollectionDetailState = {
  collection: {
    id: '',
    title: '',
    brief: '',
    collectionType: '' as TCollectionType,
    collectionSubType: '' as TCollectionSubType,
    detailThumbnail: {} as ICamfitMedia,
    items: [],
  } as IStoreCollectionDetail,
  paging: { skip: 0, limit: 10, hasNext: true },
  error: null,
};

export const storeCollectionDetailSlice = createSlice({
  name: 'storeCollection',
  initialState,
  reducers: {
    getStoreCollectionDetailRequest: (
      state,
      action: PayloadAction<{
        collectionId: string;
        skip: number;
        limit: number;
      }>,
    ) => {
      state.collection = {
        id: '',
        title: '',
        brief: '',
        collectionType: '' as TCollectionType,
        collectionSubType: '' as TCollectionSubType,
        detailThumbnail: {} as ICamfitMedia,
        items: [],
      };
      state.paging = {
        skip: action.payload.skip,
        limit: action.payload.limit,
        hasNext: true,
      };
      state.error = null;
    },
    getStoreCollectionDetailMoreRequest: (
      state,
      action: PayloadAction<{
        collectionId: string;
        skip: number;
        limit: number;
      }>,
    ) => {
      state.paging = {
        ...state.paging,
        skip: action.payload.skip,
      };
    },
    getStoreCollectionDetailSuccess: (
      state,
      action: PayloadAction<{
        data: IStoreCollectionDetail & { hasNext: boolean };
      }>,
    ) => {
      const { hasNext, items, ...collection } = action.payload.data;
      state.collection = {
        ...collection,
        items: [...state.collection.items, ...items],
      };
      state.paging.hasNext = hasNext;
    },
    getStoreCollectionDetailFailure: (
      state,
      action: PayloadAction<Error | string | AxiosError>,
    ) => {
      let errorMessage: string;

      if (typeof action.payload === 'string') {
        errorMessage = action.payload;
      } else if (action.payload instanceof Error) {
        if ('response' in action.payload) {
          const axiosError = action.payload as AxiosError;
          errorMessage =
            axiosError.response?.data?.message || axiosError.message;
        } else {
          errorMessage = action.payload.message;
        }
      } else {
        errorMessage = '종료된 기획전 입니다';
      }

      state.error = errorMessage;
    },
  },
});

export const {
  getStoreCollectionDetailRequest,
  getStoreCollectionDetailMoreRequest,
  getStoreCollectionDetailSuccess,
  getStoreCollectionDetailFailure,
} = storeCollectionDetailSlice.actions;

export default storeCollectionDetailSlice.reducer;

import axios, { AxiosAdapter, AxiosRequestConfig } from 'axios';
import {
  IAdditionalOpt,
  ICampingLog,
  IBookingDate,
  IBookingInfo,
  ICamp,
  ICampDetail,
  IZone,
  IZoneDetail,
  ICampExhibition,
  IServiceBrief,
  IMyReservation,
  IHoliday,
  IAnnounce,
  ICoupon,
  IResponse,
  ICertResponse,
  ICheckAuthResponse,
  IRegisterResponse,
  IBoard,
  IWriteOnBoardResponse,
  TSort,
  IToggleLikeBtnResponse,
  TBoardType,
  IComment,
  IReviseCampingLogResponse,
  TReportType,
  ILogInResponse,
  IBanner,
  ILandingPage,
  IBooking,
  IBookResponse,
  IUser,
  IMyAlarm,
  IMyComment,
  IMyInfo,
  IOpenSchedules,
  IVacancyAlarm,
  ICancelCharge,
  IBestPhoto,
  IZoneReservationStatus,
  ICheckPaymentResponse,
  IGetNonMemberBookingToken,
  TArticleType,
  IArticleContentCard,
  ISingleArticle,
  IArticle,
  IChangableOnSiteService,
  IMyReservationStatus,
  IProduct,
  IRegisterProductResponse,
  ICategoryName,
  ICategoryQuestion,
  IMyItem,
  TProductDetail,
  ICheckProductResponse,
  IProductMainBanners,
  IProductExhibition,
  IProductMainCategoryData,
  ISearchedProducts,
  IExhibition,
  IProductSearchData,
  IProductReviewStatistics,
  IPagination,
  TPlaceType,
  ICommunityMainPage,
  ISearchGeoResponse,
  IPet,
  ISelfAuthenticationResultResponse,
  IV2CouponIssueResponse,
  IV2MyCoupon,
  IUserTrackingInfo,
  IItemDetail,
  IAnotherPartnerItemInfo,
  EnumProuctExhibitions,
  IProductExhibitionsResponse,
  IRecommendExhibition,
  IZoneSiteExhibitionCamp,
  ISocialLoginRegisterResponse,
  TCampExhibitionType,
  ISearchResultCamp,
  INewSite,
  INewCampGeo,
  ICampMapPin,
  IBasicPagination,
  IAdditionalCollection,
  ICollectionMain,
  IDetailCollection,
  ISimpleFavoriteCamp,
  ITheme,
  ICheckSecretExhibitionResponse,
} from '@types';
import { getServerURL } from 'server';
import { cacheAdapterEnhancer } from 'axios-extensions';
// import { Bootpay } from '@bootpay/client-js';
// import { ISelfAuthenticationRequest } from 'store/types';
import { customHistory } from 'App';
import { OpenedMenu } from 'hooks/Board/useMenuModal';
import { IExhibitionCampRequest } from 'store/reducers/realtimeReservation';
import {
  IBanners,
  TV2CouponIssueType,
  ITossPaymentConfirmRequestPayload,
  ICancelPreBookingRequestPayload,
  IBookRequestPayload,
  IBookRePaymentRequestPayload,
  ISocialLoginSelfAuthenticationResultRequestPayload,
  IV2CouponListRequestPayload,
  TSearchFilterSelect,
  IGetCampSearchCampRequestPayload,
  ISelectedSearchFilters,
  IGetSitesRequestPayload,
  IGetZonesRequestPayload,
  IGetCampZoneFilterRequestPayload,
  IGetGeoCampsRequestPayload,
  ExhibitionRequestType,
} from 'store/types';
import { ICampGtmInfo } from 'gtm/types';
import {
  IStoreCollection,
  IStoreCollectionDetail,
  IStoreShowcase,
  IStoreStoryCollectionDetail,
  IStoreTheme,
} from 'types/store/storeTypes';
import instance, { notionInstance } from './instance';
import { getNullalbeShopbyAccessToken } from './shopby';

export const baseApi = axios.create({
  baseURL: getServerURL(),
  headers: { 'Cache-Control': 'no-cache' },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
    enabledByDefault: false,
  }),
});

const baseApiWithNullableAcessToken = axios.create({
  baseURL: getServerURL(),
  headers: {
    'Cache-Control': 'no-cache',
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
    enabledByDefault: false,
  }),
});

baseApiWithNullableAcessToken.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    let jwt = localStorage.getItem('jwt');

    if (jwt) {
      jwt = jwt.replace(/"/g, '');
    }

    let shopbyAccessToken: string | null | undefined =
      localStorage.getItem('shopbyAccessToken');

    if (shopbyAccessToken) {
      shopbyAccessToken = shopbyAccessToken.replace(/"/g, '');
    } else {
      const res = await getNullalbeShopbyAccessToken();

      if (!res) {
        return config;
      }

      localStorage.setItem('shopbyAccessToken', res?.data?.accessToken);
      shopbyAccessToken = res?.data?.accessToken;
    }

    config.headers.Authorization = jwt ? `Bearer ${jwt}` : '';
    config.headers.accessToken = shopbyAccessToken
      ? `Bearer ${shopbyAccessToken}`
      : '';

    return config;
  },
);

export const getHistoryPopCacheConfig = (config?: any) => {
  return {
    ...config,
    cache: customHistory.action === 'POP',
  };
};

export const register = async (params: {
  email: string;
  username: string;
  name: string;
  password: string;
  code: string;
  marketingAgreement: boolean;
  socialLoginId: string;
}) => {
  const res = await baseApi.post<IRegisterResponse>(
    `/auth/local/register`,
    params,
  );

  return res.data;
};

export const socialLoginRegister = async (params: {
  email: string;
  username: string;
  name: string;
  password: string;
  code: string;
  marketingAgreement: boolean;
  socialLoginId: string;
}) => {
  const res = await baseApi.post<ISocialLoginRegisterResponse>(
    `/social-logins/register `,
    params,
  );

  return res.data;
};

export const socialLoginConfirm = async (params: {
  email: string;
  username: string;
  name: string;
  password: string;
  code: string;
  marketingAgreement: boolean;
  socialLoginId: string;
  certificationId: string;
  isPass?: boolean;
}) => {
  const res = await baseApi.post(`/social-logins/confirm`, params);

  return res.data;
};

export const login = async (params: {
  identifier: string;
  password: string;
}) => {
  const res = await baseApi.post<ILogInResponse>(`/auth/local`, params);
  return res.data;
};

export const search = async (params: {
  search: string;
  city: string;
  majors: string;

  types: string;
  surroundingLeisureTypes: string;
  floorTypes: string;
  services: string;
  parkingTypes: string;
  enterTypes: string;
  activities: string;

  checkInTimestamp: number;
  checkoutTimestamp: number;
  skip: number;
  limit: number;
  sort: string;
}) => {
  const res = await baseApi.get<ICamp[]>(
    `/v2/search`,
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getSearchResultCamps = async (
  params: IGetCampSearchCampRequestPayload,
) => {
  const res = await baseApi.get<{
    data: ISearchResultCamp[];
    hasNext: boolean;
  }>(`/v1/search`, getHistoryPopCacheConfig({ params }));
  return res.data;
};

export const getSearchAd = async (params: {
  search?: string;
  city?: string;
  majors?: string;

  types?: string;
  surroundingLeisureTypes?: string;
  floorTypes?: string;
  services?: string;
  parkingTypes?: string;
  enterTypes?: string;
  activities?: string;

  checkInTimestamp?: number;
  checkoutTimestamp?: number;
  sort?: string;
}) => {
  const res = await baseApi.get<ICamp[]>(
    `/v2/camp-ads`,
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getCampCount = async (params: {
  search: string;
  city: string;
  majors: string;

  types: string;
  surroundingLeisureTypes: string;
  floorTypes: string;
  services: string;
  parkingTypes: string;
  enterTypes: string;
  activities: string;

  checkInTimestamp: number;
  checkoutTimestamp: number;
}) => {
  const res = await baseApi.get<number>(
    `/v2/search/count`,
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getMainExhibitions = async () => {
  const res = await baseApi.get<ICampExhibition[]>(
    `/v1/main-exhibitions`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const searchGeo = async (params: {
  search: string;
  city: string;
  majors: string;

  types: string;
  surroundingLeisureTypes: string;
  floorTypes: string;
  services: string;
  parkingTypes: string;
  enterTypes: string;
  activities: string;

  checkInTimestamp: number;
  checkoutTimestamp: number;
  sort: string;
  longitude: number;
  latitude: number;
  maxDistance: number;
  page: number;
}) => {
  const res = await baseApi.get<ISearchGeoResponse>(
    '/v2/search/geo',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getFilteredCampList = async (
  params: IGetGeoCampsRequestPayload,
) => {
  const res = await baseApi.get<INewCampGeo[]>(
    '/v1/search/geo',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getCampInGeo = async (
  params: IGetCampZoneFilterRequestPayload,
) => {
  const res = await baseApi.get<INewCampGeo[]>(
    `/v1/camps/${params.id}/info`,
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getAllCampMapPin = async (params: IGetGeoCampsRequestPayload) => {
  const res = await baseApi.get<ICampMapPin[]>(
    '/v1/camps/mapPins',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getRecommendExhibitions = async (params: {
  type: string;
  page: number;
  pageSize: number;
  city?: string;
  majors?: string;
  types?: string;
}) => {
  const res = await instance.get<IRecommendExhibition[]>(
    '/v1/exhibitions/recommend',
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const getExhibitions = async (params: {
  type: string;
  search?: string | null;
  isForMain?: boolean | null;
  sampleSize: number;
  page: number;
  pageSize: number;
}) => {
  const res = await baseApi.get<ICampExhibition[]>(
    '/v1/exhibitions',
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const getEventList = async (params: {
  place: TPlaceType;
  skip: number;
  limit: number; // 10 이하
}) => {
  const { place, skip, limit } = params;

  const res = await instance.get(
    `/v2/banners`,
    getHistoryPopCacheConfig({
      params: {
        place: place.value,
        skip,
        limit,
      },
    }),
  );

  return res.data;
};

export const getBannerList = async (params: {
  place: TPlaceType['value'];
  skip: number;
  limit: number; // 10 이하
}) => {
  const res = await baseApi.get(
    `/v2/banners`,
    getHistoryPopCacheConfig({
      params,
    }),
  );

  return res.data;
};

export const getV2Banners = async (params: {
  key: keyof IBanners;
  place: TPlaceType['value'];
}) => {
  const res = await baseApi.get(
    `/v2/banners`,
    getHistoryPopCacheConfig({
      params: { place: params.place },
    }),
  );

  const data = {
    key: params.key,
    data: res.data,
  };

  return data;
};

export const getCampDetail = async ({ id }: { id: string }) => {
  const jwt = localStorage.getItem('jwt');

  let res;

  if (jwt) {
    res = await instance.get<ICampDetail>(
      `/v1/camps/${id}`,
      getHistoryPopCacheConfig(),
    );
  } else {
    res = await baseApi.get<ICampDetail>(
      `/v1/camps/${id}`,
      getHistoryPopCacheConfig(),
    );
  }

  return res.data;
};

export const getZoneDetail = async (params: {
  id: string;
  adult: number;
  teen: number;
  child: number;
  startTimestamp: number;
  endTimestamp: number;
}) => {
  const jwt = localStorage.getItem('jwt');

  let res;

  if (jwt) {
    res = await instance.get<IZoneDetail>(`/v1/zones/${params.id}`, {
      params,
    });
  } else {
    res = await baseApi.get<IZoneDetail>(`/v1/zones/${params.id}`, {
      params,
    });
  }

  return res.data;
};

export const getZonesByCamp = async (params: IGetZonesRequestPayload) => {
  const res = await baseApi.get<IZone[]>(
    `/v1/camps/zones/${params.id}`,
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const countZonesByCamp = async (id: string) => {
  const res = await baseApi.get<number>(
    `/v1/camps/zones/count/${id}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const findSitesByZone = async (params: IGetZonesRequestPayload) => {
  const res = await baseApi.get<INewSite[]>(`/v1/sites/${params.id}`, {
    params,
  });
  return res.data;
};

export const countSitesByZone = async (id: string) => {
  const res = await baseApi.get<number>(
    `/v1/sites/count/${id}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getReviewsByCamp = async (params: {
  id: string;
  mediaOnly: boolean;
  limit: number;
  skip: number;
}) => {
  const res = await baseApi.get<ICampingLog[]>(
    `/v1/camps/reviews/${params.id}`,
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const countReviewsByCamp = async (params: {
  id: string;
  zoneId?: string;
  mediaOnly: boolean;
}) => {
  const res = await baseApi.get<number>(
    `/v1/camps/reviews/count/${params.id}`,
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const createPhoneVerification = async (phone: string) => {
  const res = await baseApi.post<ICertResponse>(`/phone-verifications`, {
    phone,
  });
  return res.data;
};

export const checkPhoneVerification = async (params: {
  id: string;
  certNum: string;
}) => {
  const res = await baseApi.put<IResponse>(
    `/phone-verifications/${params.id}`,
    {
      code: params.certNum,
    },
  );
  return res.data;
};

export const getServicesByZone = async (params: {
  id: string;
  limit: number;
  skip: number;
}) => {
  const res = await baseApi.get<IAdditionalOpt[]>(
    `/v1/zones/services/${params.id}`,
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const getRegisterdAdditionalUserInfo = async () => {
  const res = await instance.get('/v2/me/additionals/booking');

  return res.data;
};

export const calculateBooking = async (params: {
  siteId: string;
  checkInDate: IBookingDate;
  checkoutDate: IBookingDate;
  numOfAdults: number;
  numOfTeens: number;
  numOfChildren: number;
  numOfCars: number;
  services: IServiceBrief[];
  hasTrailer: boolean;
  hasCampingCar: boolean;
  pets: IPet[];
}) => {
  const res = await instance.post<IBooking>(`/v1/booking/calculate`, params);

  return res.data;
};

export const book = async (params: IBookRequestPayload) => {
  const res = await instance.post<IBookResponse>(`/v1/book`, params);

  return res.data;
};

export const bookRePayment = async (params: IBookRePaymentRequestPayload) => {
  const res = await instance.post<IBookResponse>(`/v1/book/re-payment`, params);

  return res.data;
};

export const checkPayment = async (params: {
  paymentId: string;
  receiptId: string;
}) => {
  const res = await baseApi.put<ICheckPaymentResponse>(
    `/v1/book/${params.paymentId}`,
    {
      receiptId: params.receiptId,
    },
  );
  return res.data;
};

export const getBookingDetail = async (params: {
  id: string;
  token: string | null;
}) => {
  const res = await instance.get<IBookingInfo>(`/v1/bookings/${params.id}`, {
    params: { token: params.token },
  });
  return res.data;
};

export const getLocationTable = async () => {
  const res = await baseApi.get<Location[]>(
    '/v1/location-table',
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const cancelBooking = async (params: {
  bookingId: string;
  token: string | null;
  refundAmount: number;
  bank: string | null;
  accountHolder: string | null;
  accountNumber: string | null;
}) => {
  const { bookingId, ...rest } = params;
  const res = await instance.put<IResponse>(
    `/v1/bookings/cancel/${bookingId}`,
    rest,
  );
  return res.data;
};

export const getCancelCharge = async (params: {
  id: string;
  token: string | null;
}) => {
  const res = await instance.get<ICancelCharge>(
    `/v1/bookings/cancel/${params.id}`,
    {
      params: { token: params.token },
    },
  );
  return res.data;
};

export const updateRefundInfo = async (params: {
  bookingId: string;
  token: string | null;
  bank: string;
  accountNumber: string;
  accountHolder: string;
}) => {
  const { bookingId, ...rest } = params;
  const res = await instance.put<IResponse>(
    `/v1/bookings/refund/${bookingId}`,
    rest,
  );
  return res.data;
};

export const findBookings = async (params: {
  skip: number;
  limit: number;
  isReviewWritten: boolean | null;
  status: IMyReservationStatus;
  timestamp?: number;
}) => {
  const res = await instance.get<IMyReservation[]>(
    `/v1/bookings`,
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const countBookings = async (params: {
  isReviewWritten: boolean | null;
  status: IMyReservationStatus;
  timestamp?: number;
}) => {
  const res = await instance.get<number>(
    `/v1/bookings/count`,
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const getBookingCountSummary = async () => {
  const res = await instance.get('/v1/bookings/summary');

  return res.data;
};

export const createReview = async ({ formData }: { formData: FormData }) => {
  const res = await instance.post<IResponse>('/v1/reviews', formData);
  return res.data;
};

export const createPhoneVerificationId = async (phone: string) => {
  const res = await baseApi.post<ICertResponse>(`/v1/email/forgot`, {
    phone,
  });
  return res.data;
};

export const checkPhoneVerificationId = async (params: {
  id: string;
  certNum: string;
}) => {
  const res = await baseApi.put<ICheckAuthResponse>(
    `/v1/email/check/${params.id}`,
    {
      code: params.certNum,
    },
  );
  return res.data;
};

export const createPhoneVerificationPw = async (params: {
  phone: string;
  username: string;
}) => {
  const res = await baseApi.post<ICertResponse>(`/v1/password/forgot`, params);
  return res.data;
};

export const checkPhoneVerificationPw = async (params: {
  id: any;
  certNum: string;
}) => {
  const res = await baseApi.put<ICheckAuthResponse>(
    `/v1/password/check/${params.id}`,
    {
      code: params.certNum,
    },
  );
  return res.data;
};

export const findReviews = async (params: {
  city: string | null;
  majors: string | null;
  types: string | null;
  locationTypes: string | null;
  userTypes: string | null;
  season: string | null;
  hasMedia: boolean | null;
  limit: number;
  timestamp: number;
}) => {
  const res = await baseApi.get<ICampingLog[]>(
    `/v1/reviews`,
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const findReview = async (id: string) => {
  const res = await instance.get<ICampingLog>(
    `/v1/reviews/${id}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const findHolidays = async () => {
  const res = await baseApi.get<IHoliday[]>(
    `/v1/holidays`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const findAnnounces = async (params: {
  isMain: boolean;
  skip: number;
  limit: number;
}) => {
  const res = await baseApi.get<IAnnounce[]>(
    `/v1/announces`,
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const findAnnounce = async (id: string) => {
  const res = await baseApi.get<IAnnounce>(
    `/v1/announces/${id}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const findUserCoupons = async (params: {
  skip: number;
  limit: number;
}) => {
  const res = await instance.get<ICoupon[]>('/v1/coupons', {
    params,
  });
  return res.data;
};

export const countUserCoupons = async () => {
  const res = await instance.get<number>('/v1/coupons/count');
  return res.data;
};

export const findExhibitionCamps = async (
  params: IExhibitionCampRequest & {
    exhibitionCode: string;
    skip: number;
    limit: number;
  },
) => {
  const res = await baseApi.get<ICamp[]>(
    `/v1/exhibitions/camp/${params.exhibitionCode}`,
    getHistoryPopCacheConfig({
      params: {
        ...params,
        skip: params.skip,
        limit: params.limit,
      },
    }),
  );
  return res.data;
};

export const findBookingToken = async (params: {
  code: string;
  contact: string;
}) => {
  const res = await baseApi.get<IGetNonMemberBookingToken>(
    '/v1/bookings/token',
    {
      params,
    },
  );
  return res.data;
};

export const findSingleExhibition = async (params: ExhibitionRequestType) => {
  const res = await baseApi.get<ICampExhibition>(
    `/v1/exhibitions/${params.id}`,
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const getCampExhibitionInfo = async (params: {
  exhibitionCode: string;
}) => {
  const { exhibitionCode } = params;
  const res = await baseApi.get<ICampExhibition>(
    `/v1/exhibitions/${exhibitionCode}`,
    getHistoryPopCacheConfig(),
  );

  return res.data;
};

export const updatePassword = async (data: {
  password: string;
  newPassword: string;
}) => {
  const res = await instance.put<IResponse>(`/v1/user/password`, data);
  return res.data;
};

export const getMyCarNumber = async () => {
  const res = await instance.get('/v1/me/carNumber');

  return res.data;
};

export const getMyPets = async () => {
  const res = await instance.get('/v1/me/pets');

  return res.data;
};

export const setMyPets = async (data: {
  name: string;
  breed: string;
  weight: number;
  height: number;
}) => {
  const res = await instance.post('/v2/user/pets', data);

  return res.data;
};

export const deleteMyPets = async (petName: string) => {
  const res = await instance.delete(`v2/user/pets/${petName}`);

  return res.data;
};

export const updateMyPets = async (data: {
  originName: string;
  name: string;
  breed: string;
  weight: number;
  height: number;
}) => {
  const { originName, ...rest } = data;

  const res = await instance.put(`v2/user/pets/${originName}`, rest);

  return res.data;
};

export const updateMyCarNumber = async (carNumber: null | string) => {
  const res = await instance.put<IResponse>('/v1/user/carNumber', {
    carNumber,
  });

  return res.data;
};

export const getMyInfo = async (forceUpdate: boolean) => {
  const res = await instance.get<IMyInfo>(
    '/v2/me',
    getHistoryPopCacheConfig({ forceUpdate }),
  );
  return res.data;
};

export const refreshMyInfo = async () => {
  const res = await instance.get<IMyInfo>('/v2/me');
  return res.data;
};

export const getUserPhoneWithTempJwt = async () => {
  const tempJwt = sessionStorage.getItem('tempJwt');

  const res = await axios
    .create({
      baseURL: getServerURL(),
      headers: {
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${tempJwt}`,
      },
      adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
        enabledByDefault: false,
      }),
    })
    .get('/v1/me/phone');

  return res.data;
};

export const updateThumbnail = async (formData: FormData) => {
  const res = await instance.put<IResponse>(`/v1/user/thumbnail`, formData);
  return res.data;
};

export const updateNickname = async (nickname: string) => {
  const res = await instance.put<IResponse>(`/v1/user/nickname`, {
    nickname,
  });
  return res.data;
};

export const getMyReviews = async (params: { skip: number; limit: number }) => {
  const res = await instance.get<ICampingLog[]>(
    '/v1/me/reviews',
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const countMyReviews = async () => {
  const res = await instance.get<number>(
    '/v1/me/reviews/count',
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getCouponOnBooking = async (params: {
  siteId: string;
  checkInTimestamp: number;
  checkoutTimestamp: number;
  skip: number;
  limit: number;
}) => {
  // limit : 50개까지 가능
  const res = await instance.get<ICoupon[]>(`/v1/coupons/site`, {
    params,
  });
  return res.data;
};

export const checkServerStatus = async () => {
  const res = await axios.get<IResponse>('https://stat.camfit.co.kr/');
  return res.data;
};

export const writeOnBoard = async (data: FormData) => {
  const res = await instance.post<IWriteOnBoardResponse>('/v1/boards', data);
  return res.data;
};

export const getPosts = async (params: {
  productId?: string;
  topic?: string; // 장비상세 - 궁금해요
  productType?: string; // 장비상세 - 궁금해요
  mainTopic?: string; // 장비 홈
  hasProduct?: boolean; // 장비 홈
  boardType: string;
  timestamp: number;
  limit: number;
}) => {
  const res = await instance.get<IBoard[]>(
    '/v1/boards',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getPost = async (boardId: string) => {
  const res = await instance.get<IBoard>(
    `/v1/boards/${boardId}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const toggleLikeBtn = async (data: { id: string; type: TBoardType }) => {
  const res = await instance.post<IToggleLikeBtnResponse>('/v1/like', data);
  return res.data;
};

export const writeCommments = async (data: FormData) => {
  const res = await instance.post<IComment>('/v1/board-comments', data);
  return res.data;
};

export const writeCommentThreads = async (data: FormData) => {
  const res = await instance.post('/v1/board-comment-threads', data);
  return res.data;
};

export const getComments = async (params: {
  boardId: string;
  timestamp: number;
  limit: number;
  sort: TSort;
}) => {
  const res = await instance.get<IComment[]>(
    `/v1/boards/${params.boardId}/comments`,
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getComment = async (commentId: string) => {
  const res = await instance.get<IComment>(`/v1/board-comments/${commentId}`);
  return res.data;
};

export const getThreads = async (params: {
  commentId: string;
  timestamp: number;
  limit: number;
}) => {
  const res = await instance.get(
    `/v1/board-comments/${params.commentId}/threads`,
    { params },
  );
  return res.data;
};

export const revisePost = async (data: {
  boardId: string;
  formData: FormData;
}) => {
  const res = await instance.put(`/v1/boards/${data.boardId}`, data.formData);
  return res.data;
};

export const reviseReview = async (data: {
  reviewId: string;
  formData: FormData;
}) => {
  const res = await instance.put<IReviseCampingLogResponse>(
    `/v1/reviews/${data.reviewId}`,
    data.formData,
  );
  return res.data;
};

export const getEmptyCamps = async (params: {
  search?: string;
  city?: string;
  majors?: string;
  types?: string;
  checkInTimestamp: number;
  checkoutTimestamp: number;
  skip: number;
  limit: number; // <= 10
}) => {
  const res = await baseApi.get<ICamp[]>('/v1/search/empty', { params });
  return res.data;
};

export const getEmptySites = async (params: {
  search?: string;
  city?: string;
  types?: string;
  checkInTimestamp: number;
  checkoutTimestamp: number;
  skip: number;
  limit: number; // <= 10
}) => {
  const res = await baseApi.get<{
    camps: IZoneSiteExhibitionCamp[];
    hasNext: boolean;
  }>('/v1/camps/empty', getHistoryPopCacheConfig({ params }));
  return res.data;
};

export const getEmptyCampsCnt = async (params: {
  search?: string;
  city?: string;
  majors?: string;
  types?: string;
  checkInTimestamp: number;
  checkoutTimestamp: number;
}) => {
  const res = await baseApi.get<number>('/v1/search/empty/count', {
    ...{ params },
    cache: true,
  });
  return res.data;
};

export const reportBoard = async (params: {
  id: string;
  type: TBoardType;
  reason: TReportType;
  additionalReason: string;
}) => {
  const res = await instance.post('/v1/board-reports', params);
  return res.data;
};

export const reviseComment = async (data: {
  formDate: FormData;
  commentId: string;
}) => {
  const res = await instance.put(`/v1/board-comments/${data.commentId}`);
  return res.data;
};

export const reviseThread = async (data: {
  formDate: FormData;
  threadId: string;
}) => {
  const res = await instance.put(`/v1/board-threads/${data.threadId}`);
  return res.data;
};

export const deleteBoard = async (params: OpenedMenu) => {
  const res = await instance.delete(
    `/v1/boards/${params.targetType}/${params.id}`,
  );
  return res.data;
};

export const getMyPosts = async (params: { skip: number; limit: number }) => {
  const res = await instance.get<IBoard[]>(
    '/v1/me/boards',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getMyPostsCnt = async () => {
  const res = await instance.get<number>(
    '/v1/me/boards/count',
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getBanners = async () => {
  const res = await baseApi.get<IBanner[]>(
    '/v1/main-banners',
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getLanding = async (id: string) => {
  const res = await baseApi.get<ILandingPage>(`/v1/banner/landing/${id}`);
  return res.data;
};

export const getMyAlarms = async (params: {
  isViewed: null | boolean; // null이면 다 불러옴
  skip: number;
  limit: number;
}) => {
  const res = await instance.get<IMyAlarm[]>('/v1/me/alarms', { params });
  return res.data;
};

export const getMyAlarmsCount = async (isViewed: null | boolean) => {
  const res = await instance.get<number>('/v1/me/alarms/count', {
    params: { isViewed },
  });
  return res.data;
};

export const updateViewedAlarm = async (alarmId: string) => {
  const res = await instance.put(`/v1/me/alarms/${alarmId}`);
  return res.data;
};

export const getMyComments = async (params: {
  skip: number;
  limit: number;
}) => {
  const res = await instance.get<IMyComment[]>(
    '/v1/me/replies',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getMyCommentsCount = async () => {
  const res = await instance.get<number>(
    '/v1/me/replies/count',
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const setUserBrief = async (brief: string) => {
  const res = await instance.put('/v1/user/brief', { brief });
  return res.data;
};

export const setUserSNS = async (data: { instagram: string; blog: string }) => {
  const res = await instance.put('/v1/user/sns', data);
  return res.data;
};

export const getCamperProfile = async (nickname: string) => {
  const jwt = localStorage.getItem('jwt');

  let res;

  if (jwt) {
    res = await instance.get(
      '/v1/camper/profile',
      getHistoryPopCacheConfig({ params: { nickname } }),
    );
  } else {
    res = await baseApi.get(
      '/v1/camper/profile',
      getHistoryPopCacheConfig({ params: { nickname } }),
    );
  }

  return res.data;
};

export const getCamperBoards = async (params: {
  type: 'boards' | 'replies' | 'reviews';
  nickname: string;
  skip: number;
  limit: number;
}) => {
  const res = await baseApi.get(
    `/v1/camper/${params.type}`,
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getOpenSchedules = async () => {
  const res = await baseApi.get<IOpenSchedules>(
    '/v1/open-schedules',
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const logoutInApp = async (deviceId: string) => {
  const res = await baseApi.put(`/v1/app/logout/${deviceId}`);
  return res.data;
};

export const getVacancyAlarms = async () => {
  const res = await instance.get<IVacancyAlarm[]>('/v1/me/vacancy-alarms');
  return res.data;
};

export const requestVacancyAlarms = async (data: {
  id: string;
  startTimestamp: number;
  endTimestamp: number;
}) => {
  const res = await instance.post('/v1/vacancy-alarms', data);
  return res.data;
};

export const deleteVacancyAlarms = async (id: string) => {
  const res = await instance.delete(`/v1/vacancy-alarms/${id}`);
  return res.data;
};

export const blockPost = async (params: {
  boardId: string;
  block: boolean;
}) => {
  const res = await instance.put<IResponse>(
    `/v1/board-block/${params.boardId}`,
    {
      block: params.block,
    },
  );
  return res.data;
};

export const blockUser = async (params: {
  nickname: string;
  block: boolean;
}) => {
  const res = await instance.put<IResponse>(
    `/v1/user-block/${params.nickname}`,
    {
      block: params.block,
    },
  );
  return res.data;
};

export const getBlockedUserss = async (params: {
  skip: number;
  limit: number;
}) => {
  const res = await instance.get<IUser>(
    '/v1/me/user-blocks',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getBlockedUserssCount = async () => {
  const res = await instance.get(
    '/v1/me/user-blocks/count',
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getBestPhotos = async (params: {
  skip: number;
  limit: number;
}) => {
  const res = await baseApi.get<IBestPhoto[]>(
    '/v1/best/photos',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getSingleCampAnnounce = async (announceId: string) => {
  const res = await baseApi.get(
    `/v1/camp-announces/${announceId}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getCampAnnounces = async (params: {
  search: string;
  campId: string;
  page: number;
  pageSize: number;
}) => {
  const res = await baseApi.get(
    '/v1/camp-announces',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getCampAnnouncesCnt = async (params: {
  search: string;
  campId: string;
}) => {
  const res = await baseApi.get<number>(
    '/v1/camp-announces/count',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getZoneReservationStatus = async (params: {
  zoneId: string;
  year: number;
  month: number;
}) => {
  const { zoneId, ...rest } = params;
  const res = await baseApi.get<IZoneReservationStatus>(
    `/v1/zone-calendars/${zoneId}`,
    {
      params: rest,
    },
  );
  return res.data;
};

export const getArticles = async (params: {
  articleType: TArticleType;
  page: number;
  pageSize: number;
}) => {
  const res = await baseApi.get<IArticle[]>(
    '/v1/articles',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getArticlesCount = async (articleType: TArticleType) => {
  const res = await baseApi.get<number>(
    '/v1/articles/count',
    getHistoryPopCacheConfig({ params: articleType }),
  );
};

export const getSingleArticle = async (articleId: string) => {
  const res = await baseApi.get<ISingleArticle>(
    `/v1/articles/${articleId}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getArticleContents = async (params: {
  parentType: 'article' | 'board';
  parentId: string; // article이면 article ID, board면 board ID
  skip: number;
  limit: number;
}) => {
  const res = await baseApi.get<IArticleContentCard[]>(
    '/v1/cards',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const changeAdditionalOpts = async (params: {
  bookingId: string;
  token: string; // 예약 토큰 (jwt 안보낼 때만 필수)
  services: {
    id: string; // serviceId
    quantity: number; // 옵션 수량 (재고기능 안 쓸 때만 필수)
    dailyUsages: [
      // 날짜별 옵션 수량 (재고기능 쓰는 경우만 필수)
      {
        timestamp: number; // 옵션 쓸 날짜
        dailyQuantity: number; // 해당 날짜에 쓸 수량
      },
    ];
  }[];
}) => {
  const { bookingId, ...rest } = params;
  const res = await instance.put(`/v1/bookings/service/${bookingId}`, rest);
  return res.data;
};

export const getChangableOnSiteServices = async (params: {
  bookingId: string;
  token: string;
}) => {
  const res = await instance.get<IChangableOnSiteService>(
    `/v1/bookings/service/${params.bookingId}`,
    { params: { token: params.token } },
  );
  return res.data;
};

export const getPendingBanners = async () => {
  const res = await baseApi.get<IBanner[]>('/v1/pending-banners');
  return res.data;
};

export const getMyAppPushStatus = async (deviceId: string) => {
  const res = await baseApi.get(`/v1/app/token/${deviceId}`);
  return res.data;
};

export const toggleAppPush = async (params: {
  deviceId: string;
  isAdDenied: boolean;
}) => {
  const res = await baseApi.put(`/v1/app/token/${params.deviceId}`, {
    isAdDenied: params.isAdDenied,
  });
  return res.data;
};

export const toggleMarketingAgreement = async (marketingAgreement: boolean) => {
  const res = await instance.put('/v1/user/agreement', { marketingAgreement });
  return res.data;
};

export const getMainMagazine = async () => {
  const res = await baseApi.get('/v1/articles/random');
  return res.data;
};

export const searchProductsBrief = async (params: {
  search: string;
  type: string;
  skip: number;
  limit: number;
  cache?: boolean;
}) => {
  const { search, type, skip, limit, cache } = params;

  const res = await instance.get<IProduct[]>('/v1/products/brief', {
    params: { search, type, skip, limit },
    cache: cache || true,
  });
  return res.data;
};

export const registerProduct = async (params: {
  brand: string;
  name: string;
  vendor: string | null;
  type: string;
}) => {
  const res = await instance.post<IRegisterProductResponse>(
    '/v1/products/custom',
    params,
  );
  return res.data;
};

export const getQuestionsByCategory = async (categoryId: string) => {
  const res = await baseApi.get<ICategoryQuestion[]>(
    `/v1/category/questions/${categoryId}`,
  );
  return res.data;
};

export const uploadMyItem = async (formDate: FormData) => {
  const res = await instance.post<IResponse>('/v1/equipments', formDate);
  return res.data;
};

export const getMyItems = async (params: {
  type: string;
  skip: number;
  limit: number;
  isMyEquipment?: boolean; // 내 장비 여부, default : true, 사용 리뷰 가져올 때만 false로 주면 됨
}) => {
  const res = await instance.get<IMyItem[]>(
    '/v1/equipments',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getMyItem = async (equipmentId: string) => {
  const res = await instance.get<IMyItem>(
    `/v1/equipments/${equipmentId}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const deleteMyItem = async (equipmentId: string) => {
  const res = await instance.delete(`/v1/equipments/${equipmentId}`);
  return res.data;
};

export const reviseMyItem = async (params: {
  equipmentId: string;
  formData: FormData;
}) => {
  const res = await instance.put(
    `/v1/equipments/${params.equipmentId}`,
    params.formData,
  );
  return res.data;
};

export const withdraw = async (params: {
  hasAgreedWithdrawalPolicies: boolean;
  password: string;
  reason: string;
}) => {
  const res = await instance.put('/v1/user/withdraw', params);

  return res.data;
};

export const getWithdrawInfo = async () => {
  const res = await instance.get('/v1/user/withdraw');

  return res.data;
};

export const getProductDetail = async (productId: string) => {
  const res = await instance.get<TProductDetail>(
    `/v1/products/${productId}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getArticlesByProduct = async (productId: string) => {
  const res = await baseApi.get<IArticle[]>(
    `/v1/products/articles/${productId}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getProductShotsMore = async (params: {
  lastTimestamp: number;
  productId: string;
}) => {
  const res = await instance.get<TProductDetail>(
    `/v1/products/reviews/medias/${params.productId}?timestamp=${params.lastTimestamp}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getProductStatistics = async (productId: string) => {
  const res = await baseApi.get<IProductReviewStatistics>(
    `/v1/products/statistics/${productId}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const reviseProductInfo = async (params: {
  productId: string;
  text: string;
}) => {
  const res = await instance.post('/v1/products/request', params);
  return res.data;
};

export const getCamperItems = async (params: {
  productId?: string;
  isOwnerOnly?: boolean;
  hasMedia?: boolean;
  nickname?: string; // 다른유저프로필
  type?: string; // 다른 유저프로필
  isProductExamed?: boolean; // true면 검수된 상품이 태그된 리뷰만 보기;
  skip: number;
  limit: number; // 최대 5;
  isHidden?: boolean;
}) => {
  const res = await instance.get<IMyItem[]>(
    '/v1/camper/equipments',
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const checkProduct = async (productId: string) => {
  const res = await instance.get<ICheckProductResponse>(
    `/v1/equipments/check/${productId}`,
  );
  return res.data;
};

export const getCamperItem = async (equipmentId: string) => {
  const res = await instance.get<IMyItem>(
    `/v1/camper/equipments/${equipmentId}`,
  );
  return res.data;
};

export const getV2Curations = async (params: {
  key: keyof IBanners;
  place: TPlaceType['value'];
}) => {
  const res = await instance.get(
    `/v2/curations`,
    getHistoryPopCacheConfig({
      params: { place: params.place, page: 1, pageSize: 50 },
    }),
  );

  return res.data;
};

export const getProductMainBanners = async () => {
  const res = await instance.get<IProductMainBanners>(
    '/v1/main-banners/product',
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getProductMainExhibitions = async () => {
  const res = await baseApi.get<IProductExhibition[]>(
    '/v1/main-exhibitions/product',
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

// Note : V1 API, 해당 API V2 업데이트시 제거.
export const getProductPageData = async (categoryId: string) => {
  const res = await baseApi.get<IProductMainCategoryData>(
    `/v1/products/category/display/${categoryId}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

// Note : V2 API
export const getProductMainCategoryData = async (categoryId: string) => {
  const res = await baseApi.get<IProductMainCategoryData>(
    `/v2/products/category/display/${categoryId}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getRelatedProductListCount = async (productId: string) => {
  const res = await baseApi.get<number>(
    `/v2/products/related/count/${productId}`,
  );
  return res.data;
};

export const getRelatedProductList = async (params: {
  productId: string;
  skip: number; // dafault : 0
  limit: number; // dafault : 5
  forceUpdate?: boolean;
}) => {
  const { productId, skip, limit, forceUpdate = false } = params;
  const res = await baseApi.get(
    `/v2/products/related/${productId}`,
    getHistoryPopCacheConfig({
      params: { skip, limit },
      forceUpdate,
    }),
  );

  return { data: res.data, limit };
};

export const searchProducts = async (params: {
  q: string; // 검색어
  cat: string; // categoryId
  s?:
    | 'averageRateDesc'
    | 'averageRateAsc'
    | 'priceDesc'
    | 'priceAsc'
    | 'viewDesc'
    | 'viewAsc'
    | 'reviewDesc'
    | 'reviewAsc'; // sort, 안보내면 캠핏 추천순
  p: number; // page
  ps: number; // pageSize(최대 30)
}) => {
  const res = await baseApi.get<ISearchedProducts>(
    '/v1/products/search',
    getHistoryPopCacheConfig({
      params,
    }),
  );
  return res.data;
};

export const getProductExhibitions = async (parmas: {
  sampleSize: number;
  sort: EnumProuctExhibitions;
  page: number;
  pageSize: number;
}) => {
  const res = await baseApi.get<IProductExhibitionsResponse[]>(
    `/v1/product-exhibitions`,
    {
      params: {
        sampleSize: parmas.sampleSize,
        sort: EnumProuctExhibitions.undefined,
        page: parmas.page,
        pageSize: parmas.pageSize,
      },
    },
  );

  return res.data;
};

export const getSingleProductExhibition = async (code: string) => {
  const res = await baseApi.get<IExhibition>(
    `/v1/product-exhibitions/${code}`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getExhibitionProducts = async (params: {
  code: string;
  skip: number;
  limit: number;
}) => {
  const { code, ...rest } = params;
  const res = await baseApi.get<IProduct[]>(
    `/v1/product-exhibitions/product/${code}`,
    getHistoryPopCacheConfig({ params: rest }),
  );
  return res.data;
};

export const getProductSearchData = async () => {
  const res = await baseApi.get<IProductSearchData>(
    '/v1/products/search/data',
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

export const getNonReservList = async (params: {
  code: string;
  verificationId: string;
}) => {
  const res = await baseApi.get(
    `/v1/booking-code/check/${params.verificationId}`,
    {
      params: {
        code: params.code,
      },
    },
  );
  return res.data;
};

export const autoComplete = async (q: string) => {
  const res = await axios.get<string[]>(
    'https://autocomplete.camfit.co.kr/v1/products/search/autocomplete',
    { params: { q } },
  );
  return res.data;
};

export const campAutoComplete = async (q: string) => {
  const res = await axios.get<string[]>(
    'https://autocomplete.camfit.co.kr/v1/camps/search/autocomplete',
    { params: { q } },
  );
  return res.data;
};

export const getRealtimeReservationData = async () => {
  const res = await instance.get('/v2/home/search');
  return res.data;
};

export const postPhoneAuth = async (params: {
  name: string;
  phone: string;
}) => {
  const res = await baseApi.post(`/v1/booking-code/forgot`, params);
  return res.data;
};

export const readAllMyAlarms = async () => {
  const res = await instance.put(`/v1/me/alarms/all`);
  return res.data;
};

export const getCommunityBanners = async () => {
  const res = await baseApi.get(`/v1/main-banners/board`);
  return res.data;
};

export const updatePurchase = async (params: { id: string }) => {
  const res = await baseApi.put<IResponse>(
    `/v1/products/purchase/${params.id}`,
  );
  return res.data;
};

export const reportUser = async (params: {
  nickname: string;
  reason: TReportType;
  additionalReason: string;
  link: string;
}) => {
  const res = await instance.post('/v1/user-reports', params);
  return res.data;
};

export const deleteCampingLog = async (id: string) => {
  const res = await instance.delete(`/v1/reviews/${id}`);
  return res.data;
};

export const deleteBookmark = async ({ campId }: { campId: string }) => {
  const res = await instance.delete(`/v1/bookmark/camp/${campId}`);
  return { ...res.data, campId };
};

export const createBookmark = async ({ camp }: { camp: ICampGtmInfo }) => {
  const res = await instance.post(`/v1/bookmark/camp/${camp.id}`);
  return res.data;
};

export const getMyBookmarks = async (params: {
  skip: number;
  limit: number;
  forceUpdate?: boolean;
}) => {
  const { skip, limit, forceUpdate = false } = params;
  const res = await instance.get<IPagination>(
    `/v1/bookmark/camp`,
    getHistoryPopCacheConfig({
      params: { skip, limit },
      forceUpdate,
    }),
  );
  return res.data;
};

export const getMyBookmarkCount = async (params: {
  forceUpdate: boolean | undefined;
}) => {
  const { forceUpdate } = params;
  const res = await instance.get(
    `/v1/bookmark/camp/count`,
    getHistoryPopCacheConfig({ forceUpdate }),
  );
  return { count: res.data, forceUpdate };
};

export const deleteBulkBookmark = async ({
  campIds,
}: {
  campIds: string[];
}) => {
  const res = await instance.put(`/v1/bookmark/camp/bulk`, {
    campIds,
  });
  const newResponseData = {
    ...res.data,
    campIds,
  };
  return newResponseData;
};

export const changeAppPushOnOff = async (bookmarkId: string) => {
  const res = await instance.put(`/v1/bookmark/camp/${bookmarkId}`);

  const newResponseData = {
    ...res.data,
    changedBookmarkId: bookmarkId,
  };
  return newResponseData;
};

export const getCommunityHomeDate = async () => {
  const res = await baseApi.get<ICommunityMainPage>(
    '/v2/home/community',
    getHistoryPopCacheConfig(),
  );

  return res.data;
};

// export const getSelfAuthentication = async (
//   params: ISelfAuthenticationRequest,
// ) => {
//   const res = await Bootpay.requestAuthentication(params);

//   return res;
// };

export const getPolicyHosts = async (params: {
  page: number;
  pageSize: number;
}) => {
  const res = await baseApi.get<string[]>(
    '/v2/policies/privacy/host',
    getHistoryPopCacheConfig({ params }),
  );

  return res.data;
};

export const getPolicyHostCount = async () => {
  const res = await baseApi.get<number>('/v2/policies/privacy/host/count', {
    cache: true,
  });

  return res.data;
};

export const getPolicySupplier = async (params: {
  page: number;
  pageSize: number;
}) => {
  const res = await baseApi.get<string[]>(
    '/v2/policies/privacy/supplier',
    getHistoryPopCacheConfig({ params }),
  );

  return res.data;
};

export const getPolicySupplierCount = async () => {
  const res = await baseApi.get<number>('/v2/policies/privacy/supplier/count', {
    cache: true,
  });

  return res.data;
};

export const getSelfAuthenticationResult = async ({
  receiptId,
  certificatePage,
  isCheckMarketingTerm,
}: {
  receiptId: string;
  certificatePage: string;
  isCheckMarketingTerm?: boolean;
}) => {
  let res;
  if (certificatePage === 'dormant') {
    const tempJwt = sessionStorage.getItem('tempJwt');

    res = await axios
      .create({
        baseURL: getServerURL(),
        headers: {
          'Cache-Control': 'no-cache',
          Authorization: `Bearer ${tempJwt}`,
        },
        adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
          enabledByDefault: false,
        }),
      })
      .put(`/v2/hibernations/awake/${receiptId}`, {
        marketingAgreement: isCheckMarketingTerm,
      });
  } else if (certificatePage === 'signup') {
    res = await baseApi.get<ISelfAuthenticationResultResponse>(
      `/v2/users/certificate/${receiptId}`,
    );
  } else {
    res = await instance.put<ISelfAuthenticationResultResponse>(
      `/v2/user/privacy/${receiptId}`,
    );
  }

  return res.data;
};

export const getSocialSelfAuthenticationResult = async ({
  receiptId,
  socialLoginId,
}: ISocialLoginSelfAuthenticationResultRequestPayload) => {
  const res = await baseApi.post<ISelfAuthenticationResultResponse>(
    `/v1/social-logins/certificate`,
    {
      receiptId,
      socialLoginId,
    },
  );

  return res.data;
};

export const putAuthenticationAndUpdatePhoneNumber = async (params: {
  receiptId: string;
}) => {
  const res = await instance.put(`v1/users/phone`, params);

  return res.data;
};

export const recordClickAd = async (adId: string) => {
  const res = await baseApi.put(`/v2/camps/advertisements/click/${adId}`);

  return res.data;
};

export const getV2Main = async () => {
  const res = await instance.get<any>(
    `/v2/home/main`,
    getHistoryPopCacheConfig(),
  );

  return res.data;
};

export const getV2CouponList = async (parameters: {
  params: IV2CouponListRequestPayload;
  forceUpdate?: boolean;
}) => {
  const { params, forceUpdate } = parameters;

  const res = await instance.get<any>(
    `/v2/coupons/downloadable`,
    getHistoryPopCacheConfig({
      params,
      forceUpdate,
    }),
  );
  return res.data;
};

export const getZonesCoupons = async (parameters: {
  zoneId: string;
  startTimestamp: number;
  endTimestamp: number;
  price: number;
}) => {
  const { zoneId, startTimestamp, endTimestamp, price } = parameters;

  const res = await baseApi.get(
    `/v1/zones/${zoneId}/coupons?startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}&price=${price}`,
  );

  return res.data;
};

export const getCouponDetail = async (parameters: { couponId: string }) => {
  const { couponId } = parameters;

  const res = await baseApi.get<ICoupon>(`/v1/coupons/${couponId}`);

  return res.data;
};

export const submitSelfCheckin = async (params: {
  bookingId: string;
  selfCheckinAnswerData: {
    title: string;
    answer: string | null;
  }[];
}) => {
  const { bookingId, selfCheckinAnswerData } = params;
  const res = await instance.put<IResponse>(
    `/v2/bookings/self/check-in/${bookingId}`,
    selfCheckinAnswerData,
  );
  return res.data;
};

export const getMyCouponList = async (params: {
  skip: number;
  limit: number;
}) => {
  const res = await instance.get<IV2MyCoupon[]>('/v1/coupons', { params });
  return res.data;
};

/* 
couponId : string
issueType : enum("download" | "code")
issueCode : issueType이 "code"인 경우 string (필수), "download"인 경우 필요없음 
*/

export const issueV2Coupon = async (parameters: {
  params: {
    couponId?: string;
    issueType: TV2CouponIssueType;
    issueCode?: string;
  };
}) => {
  const { params } = parameters;
  const res = await instance.post<IV2CouponIssueResponse>(
    '/v2/coupons/issue/code',
    params,
  );

  return res.data;
};

export const getUserTrackingInfo = async () => {
  const res = await instance.get<IUserTrackingInfo>('/v2/users/tid');
  return res.data;
};

export const algoliaSearchProduct = async (params: {
  searchIndex: any;
  query: string;
  page: number;
  userToken?: string;
  restrictAttributes?: [];
  facetFilters?: [];
  numericFilters?: [];
  hitsPerPage?: number;
}) => {
  const {
    searchIndex,
    query,
    page,
    userToken,
    restrictAttributes = [],
    facetFilters = [],
    numericFilters = [],
    hitsPerPage,
  } = params;

  const res = await searchIndex.search(`${query}`, {
    clickAnalytics: true,
    userToken: userToken || null,
    hitsPerPage: hitsPerPage || 48,
    restrictSearchableAttributes: restrictAttributes,
    facetFilters,
    numericFilters,
    page,
  });

  return res;
};

export const algoliaGetStoreFacet = async (params: {
  searchClient: any;
  queries: any;
}) => {
  const { searchClient, queries } = params;

  const res = await searchClient.multipleQueries(queries);

  return res;
};

export const algoliaGetTrendingItems = async (recommendClient: any) => {
  const res = await recommendClient.getTrendingItems([
    {
      indexName: 'product',
    },
  ]);
  return res;
};

export const algoliaGetRelatedProducts = async (params: {
  recommendClient: any;
  objectID: string;
}) => {
  const { recommendClient, objectID } = params;
  const res = await recommendClient.getRelatedProducts([
    {
      indexName: 'product',
      objectID,
    },
  ]);
  return { res, objectID };
};

export const getIntroBanners = async () => {
  const res = await baseApi.get<any>(`/v2/intro/banners`);
  return res?.data?.introBanners;
  // console.log('getIntroBanners API: ');
  // localStorage.setItem('introBanner', JSON.stringify(res.data));
  // const str = localStorage.getItem('introBanner');
  // if (str) return JSON.parse(str).introBanners;
};

export const getBackendVersion = async () => {
  const res = await baseApi.get<{ status: string; version?: string }>(
    `/v2/version`,
    getHistoryPopCacheConfig(),
  );
  return res.data;
};

/*
STORE
*/

export const getAnotherPartnerList = async (productId: string) => {
  const res = await baseApi.get<IAnotherPartnerItemInfo[]>(
    `/v2/products/items/${productId}`,
  );

  return res.data;
};

export const getShopbyItemDetail = async (shopbyProductNo: string) => {
  const res = await baseApiWithNullableAcessToken.get<IItemDetail>(
    `/v2/items/${shopbyProductNo}`,
  );

  return res.data;
};

export const getNotionDatabase = async (params: {
  database_id: string;
  filter?: any;
  sort?: any;
}) => {
  const res = await notionInstance.get<any>('/api/database', { params });
  return res;
};

export const getNotionBlock = async (params: { block_id: string }) => {
  const res = await notionInstance.get<any>('/api/block', { params });
  return res;
};

export const getNotionBlockChildren = async (params: {
  block_id: string;
  page_size: number;
}) => {
  const res = await notionInstance.get<any>('/api/block/children', { params });
  return res;
};

export const createNotionPage = async (body: any) => {
  const res = await notionInstance.post<any>('/api/page', body);
  return res;
};

export const paymentsConfirm = async (
  body: ITossPaymentConfirmRequestPayload,
) => {
  const res = await baseApi.post('/payments/confirm', body);

  return res.data;
};

// cancelPreBooking
export const cancelPreBooking = async (
  body: ICancelPreBookingRequestPayload,
) => {
  const res = await baseApi.post('/v1/bookings/pre-cancel', body);

  return res.data;
};

export const loginWithKakao = async (code: string) => {
  const res = await baseApi.get('/connect/kakao', { params: { code } });

  return res.data;
};

export const loginWithGoogle = async (code: string) => {
  const res = await baseApi.get('/connect/google', { params: { code } });

  return res.data;
};

export const loginWithApple = async (code: string) => {
  const res = await baseApi.get('/connect/apple', { params: { code } });

  return res.data;
};

export const getFeatureFlags = async (param: { key: string }) => {
  const { key } = param;

  const res = await baseApi.get(`/v1/feature-flags/${key}`);

  return res.data;
};

export const getSiteExhibition = async (params: {
  key: string;
  code: string;
}) => {
  const res = await baseApi.get(
    `/v1/site-exhibitions/${params.code}/site-curator`,
  );

  return res.data;
};

export const getExhibitionSites = async (params: {
  key: string;
  id: string;
  startTimestamp: number;
  page: number;
  pageSize: number;
}) => {
  const { startTimestamp, page, pageSize } = params;
  const res = await baseApi.get(`/v1/site-curators/${params.id}`, {
    params: { startTimestamp, page, pageSize },
  });

  return res.data;
};

export const getCampExhibitionCodeList = async (params: {
  key: TCampExhibitionType;
}) => {
  const { key } = params;
  const res = await instance.get(`/v1/exhibitions/${key}/code`);

  return res.data;
};

export const getSearchFilterInfo = async (select?: TSearchFilterSelect) => {
  let jwt = localStorage.getItem('jwt');

  if (jwt) {
    jwt = jwt.replace(/"/g, '');
  }

  const res = await baseApi.get(`/v1/search/filter`, {
    params: { select },
    headers: { Authorization: jwt ? `Bearer ${jwt}` : '' },
  });

  return res.data;
};

export const campSearchAutoComplete = async (search: string) => {
  const res = await baseApi.get(`/v1/camps/search/autocomplete`, {
    params: { search },
  });

  return res.data;
};

export const getSearchResultAd = async (
  params: IGetCampSearchCampRequestPayload,
) => {
  const res = await baseApi.get<ISearchResultCamp[]>(
    `/v1/search/ads`,
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getSearchResultCount = async (
  params: IGetCampSearchCampRequestPayload,
) => {
  const res = await baseApi.get<number>(
    `/v1/search/count`,
    getHistoryPopCacheConfig({ params }),
  );
  return res.data;
};

export const getSimpleMyFavoriteCamps = async (params: IBasicPagination) => {
  const res = await instance.get<ISimpleFavoriteCamp[]>(
    `v1/bookmark/camp/simple`,
    getHistoryPopCacheConfig({ params }),
  );

  return res.data;
};

export const getSearchMainTheme = async (params: IBasicPagination) => {
  const res = await instance.get<ITheme[]>(
    `v1/themes`,
    getHistoryPopCacheConfig({ params }),
  );

  return res.data;
};

export const getThemeCampList = async (
  params: {
    key: string;
    skip: number;
    limit: number;
    searchQuery: string;
  } & IGetCampSearchCampRequestPayload,
) => {
  const { key, searchQuery, ...restParams } = params;

  const res = await instance.get(
    `v1/themes/${key}/camps`,
    getHistoryPopCacheConfig({ params: restParams }),
  );

  return res.data;
};

export const getMainCollections = async (params: IBasicPagination) => {
  const res = await instance.get<ICollectionMain[]>(
    `v1/collections`,
    getHistoryPopCacheConfig({ params }),
  );

  return res.data;
};

export const getCollectionDetail = async ({
  id,
  requestPayload,
}: {
  id: string;
  requestPayload: IGetCampSearchCampRequestPayload;
}) => {
  const res = await instance.get<{
    _id: string;
    name: string;
  }>(
    `v1/collections/${id}`,
    getHistoryPopCacheConfig({
      params: requestPayload,
    }),
  );
  return res.data;
};

export const getCollectionItems = async (
  params: {
    id: string;
    requestPayload: IGetCampSearchCampRequestPayload;
    limit: number;
    skip: number;
  } & IGetCampSearchCampRequestPayload,
) => {
  const { id, requestPayload, limit, skip } = params;
  const res = await instance.get<IDetailCollection[] | IAdditionalCollection[]>(
    `v1/collections/${id}/camps`,
    getHistoryPopCacheConfig({
      params: { limit, skip, ...requestPayload },
    }),
  );

  return res.data;
};

export const checkIsSecretExhibition = async (eventNo: string) => {
  const res = await instance.get<ICheckSecretExhibitionResponse>(
    `v1/shop-by-exhibitions/${eventNo}`,
  );
  return { ...res.data, eventNo };
};

export const postJwtError = async (params: any) => {
  const res = await baseApi.post<any>('/v2/jwt/error', params);

  return res.data;
};

export const getInventoryFilters = async () => {
  let jwt = localStorage.getItem('jwt');

  if (jwt) {
    jwt = jwt.replace(/"/g, '');
  }

  const res = await baseApi.get<{
    data: ISearchResultCamp[];
    hasNext: boolean;
  }>(`/v1/inventory/filter`, {
    headers: { Authorization: jwt ? `Bearer ${jwt}` : '' },
    cache: customHistory.action === 'POP',
  });

  return res.data;
};

export const getNewBadge = async () => {
  const res = await baseApi.get(`/v1/new-badges`);

  return res.data;
};

/* Store CPR */

export const getStoreThemeList = async () => {
  const res = await instance.get<IStoreTheme[]>(`/v1/store-themes`);

  return res.data;
};

export const getStoreShowcaseList = async () => {
  const res = await instance.get<IStoreShowcase[]>(`/v1/store-showcases`);

  return res.data;
};

export const getStoreCollectionList = async () => {
  const res = await instance.get<IStoreCollection[]>(`/v1/store-collections`);

  return res.data;
};

export const getCamfitStoreItemDetail = async (itemId: string) => {
  const res = await instance.get<IItemDetail>(`/v1/store-items/${itemId}`);

  return res.data;
};

export const getStoreCollectionDetail = async (params: {
  collectionId: string;
  skip: number;
  limit: number;
}) => {
  const { collectionId, skip, limit } = params;

  const res = await instance.get<
    IStoreCollectionDetail | IStoreStoryCollectionDetail
  >(`/v1/store-collections/${collectionId}`, {
    params: { skip, limit },
  });

  return res.data;
};

export const getStoreStoryCollectionDetailItem = async (params: {
  key: string;
  skip: number;
  limit: number;
}) => {
  const { key, skip, limit } = params;

  const res = await instance.get<IItemDetail[]>(
    `/v1/store-stories/${key}/items`,
    {
      params: { skip, limit },
    },
  );

  return res.data;
};

export const getStoreSearch = async (params: {
  search: string;
  minPrice?: number;
  maxPrice?: number;
  brands?: string;
  sort?: string;
  skip: number;
  limit: number;
  category?: string;
  isFirstLoad?: boolean;
}) => {
  const {
    search,
    minPrice,
    maxPrice,
    brands,
    sort,
    skip,
    limit,
    category,
    isFirstLoad,
  } = params;

  const res = await instance.get(`/v1/store/search`, {
    params: {
      search,
      minPrice,
      maxPrice,
      brands,
      sort,
      skip,
      limit,
      category,
      isFirstLoad,
    },
  });

  return { ...res.data, requestPayload: params };
};

export const getStoreSearchAutoCompleteKeyword = async (params: {
  keyword: string;
}) => {
  const { keyword } = params;

  const res = await instance.get(`/v1/store/search/autocomplete`, {
    params: { search: keyword },
  });

  return res.data;
};

export const getStoreSearchRecommendKeyword = async () => {
  const res = await instance.get(`/v1/store/search/suggested`);

  return res.data;
};

export const getStoreSearchPopularKeyword = async () => {
  const res = await instance.get(`/v1/store/search/popular`);

  return res.data;
};

export const getStoreCategoryList = async () => {
  const res = await baseApi.get(`/v1/store/category-home`);

  return res.data;
};

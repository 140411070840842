import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer';
import {
  IFeedCampaign,
  IFeedPostScore,
  IFeedQuration,
  TFeedCache,
  TFeeds,
} from 'types/feed';

interface Props {
  feedInfo: IFeedQuration | null;
  // feedCampaigns: IFeedCampaign[] | null;
  // feedAdPostIds: Set<string>;
  singleFeedData: {
    name: string;
    data: TFeeds[];
    registerTimestamp: number;
  }[];
  singleFeedName: string | null;
  singleActiveTabIndex: number;
  singleFeedPage: number[];
  singleFeedPageSize: number;

  /** 피드 상세 진입 여부 */
  isSingleFeedTouring: boolean;
  /** window 최상단에서 얼마만큼 스크롤 내렸는지 여부. */
  singleFeedScrollTop: number;
  singleFeedCacheMap: CellMeasurerCache;
  /** 마지막 노출된 게시물 index */
  singleFeedScrollToIndex: number;
  /** header의 보여짐 상태 */
  singleFeedHeaderState: '' | 'off';
  /** 리액션 lottie */
  singleFeedLottieJsons: { [key: string]: any };
  /** 캠페인 전용 로띠 */
  singleFeedCampaignLottieJsons: { [key: string]: any };

  singleFeedCache: {
    name: string;
    cache: TFeedCache[];
  }[];
}

const initialState: Props = {
  feedInfo: null,
  singleFeedData: [],
  singleFeedName: null,
  singleActiveTabIndex: 0,
  singleFeedPage: [],
  singleFeedPageSize: 30,
  isSingleFeedTouring: false,
  singleFeedScrollTop: 0,
  singleFeedCacheMap: new CellMeasurerCache({
    fixedWidth: true,
  }),
  singleFeedScrollToIndex: 0,
  /** header의 보여짐 여부 컨트롤 */
  singleFeedHeaderState: '',
  singleFeedLottieJsons: {},
  singleFeedCampaignLottieJsons: {},
  singleFeedCache: [],
};

export const directFeedSlice = createSlice({
  name: 'directFeed',
  initialState,
  reducers: {
    setFeedInfo: (state, action: PayloadAction<IFeedQuration>) => {
      state.feedInfo = action.payload;
    },
    setCampaignLottieJsons: (
      state,
      action: PayloadAction<{ id: string; jsonData: string }>,
    ) => {
      const { id, jsonData } = action.payload;
      state.singleFeedCampaignLottieJsons = {
        ...state.singleFeedCampaignLottieJsons,
        [id]: jsonData,
      };
    },
    setSingleFeedName: (state, action: PayloadAction<string>) => {
      state.singleFeedName = action.payload;
    },
    // setSingleFeedActiceIndexTab: (state, action: PayloadAction<number>) => {
    //   state.singleFeedActiveTabIndex = action.payload;
    // },
    setSingleFeedPage: (
      state,
      action: PayloadAction<{ activeTabIndex: number; value: number }>,
    ) => {
      const { activeTabIndex, value } = action.payload;

      state.singleFeedPage[activeTabIndex] = value;
    },
    setSingleFeedScrollTop: (state, action: PayloadAction<number>) => {
      state.singleFeedScrollTop = action.payload;
    },
    setSingleFeedCacheMap: (
      state,
      action: PayloadAction<CellMeasurerCache>,
    ) => {
      state.singleFeedCacheMap = action.payload;
    },
    setSingleFeedScrollToIndex: (state, action: PayloadAction<number>) => {
      state.singleFeedScrollToIndex = action.payload;
    },
    onSingleFeedTouring: state => {
      state.isSingleFeedTouring = true;
    },
    offSingleFeedTouring: state => {
      state.isSingleFeedTouring = false;
    },

    setSingleFeedHeaderState: (state, action: PayloadAction<'' | 'off'>) => {
      state.singleFeedHeaderState = action.payload;
    },

    initSingleFeedData: (state, action: PayloadAction<string[]>) => {
      const feedNames = action.payload;

      state.singleFeedPage = new Array(feedNames.length).fill(1);

      state.singleFeedData = feedNames.map(feedName => {
        return {
          name: feedName,
          data: [],
          registerTimestamp: 0,
        };
      });

      state.singleFeedCache = feedNames.map(feedName => {
        return {
          name: feedName,
          cache: [],
        };
      });
    },

    setFirstSingleFeedData: (
      state,
      action: PayloadAction<{
        name: string;
        data: TFeeds[];
      }>,
    ) => {
      const { name, data } = action.payload;

      const targetIndex = state.singleFeedData.findIndex(
        feed => feed.name === name,
      );
      state.singleFeedPage[targetIndex] = 1;

      state.singleFeedData = state.singleFeedData.map(feed =>
        feed.name !== name
          ? feed
          : {
              name,
              data,
              registerTimestamp: new Date().getTime(),
            },
      );

      state.singleFeedCache = state.singleFeedCache.map(cache =>
        cache.name !== name
          ? cache
          : {
              name,
              cache: data.map(el => {
                return {
                  isMoreBtnClicked: false,
                  isCampInfoShow: false,
                };
              }),
            },
      );
    },

    changeSingleFeedData: (
      state,
      action: PayloadAction<{ name: string; data: TFeeds[] }>,
    ) => {
      const { name, data } = action.payload;

      state.singleFeedData = state.singleFeedData.map(feed =>
        feed.name !== name
          ? feed
          : { name, data, registerTimestamp: new Date().getTime() },
      );
    },

    appendSingleFeedData: (
      state,
      action: PayloadAction<{ name: string; data: TFeeds[] }>,
    ) => {
      const { name, data } = action.payload;

      state.singleFeedData = state.singleFeedData.map(feed =>
        feed.name !== name
          ? feed
          : {
              name,
              data: [...feed.data, ...data],
              registerTimestamp: new Date().getTime(),
            },
      );

      const appendCache =
        name === 'Review'
          ? new Array(data.length).fill({
              isMoreBtnClicked: false,
              isCampInfoShow: false,
            })
          : new Array(data.length).fill({ isMoreBtnClicked: false });

      state.singleFeedCache = state.singleFeedCache.map(cache =>
        cache.name !== name
          ? cache
          : {
              name,
              cache: [...cache.cache, ...appendCache],
            },
      );
    },

    // appendFeedAdPostId: (state, action: PayloadAction<string>) => {
    //   const feedId = action.payload;
    //   state.singleFeedAdPostIds.add(feedId);
    // },

    resetSingleFeedDataAll: state => {
      state.isSingleFeedTouring = false;
      state.singleFeedScrollTop = 0;
      state.singleFeedCacheMap = new CellMeasurerCache({
        fixedWidth: true,
      });
      state.singleFeedScrollToIndex = 0;
      state.singleFeedHeaderState = '';
      // state.feedAdPostIds = new Set();
    },

    /**
     * 로그인 시 새로운 데이터를 받아오기 위해 registerTimestamp reset 처리
     * 참조 슬랙
     * @description https://nexteditionworkspace.slack.com/archives/C06QPRFSBRD/p1724461843839659
     */
    resetRegisterTimestamp: state => {
      state.singleFeedData = state.singleFeedData.map(feed => {
        return {
          ...feed,
          registerTimestamp: 0,
        };
      });
    },

    setLottieJsons: (
      state,
      action: PayloadAction<{ id: string; jsonData: string }>,
    ) => {
      const { id, jsonData } = action.payload;
      state.singleFeedLottieJsons = {
        ...state.singleFeedLottieJsons,
        [id]: jsonData,
      };
    },

    changeFeedCache: (
      state,
      action: PayloadAction<{
        name: string;
        data: TFeedCache[];
      }>,
    ) => {
      const { name, data } = action.payload;
      state.singleFeedCache = state.singleFeedCache.map(cache =>
        cache.name !== name
          ? cache
          : {
              name,
              cache: data,
            },
      );
    },
  },
});

export const {
  setFeedInfo,
  setSingleFeedName,
  // setSingleFeedActiceIndexTab,
  setSingleFeedPage,
  onSingleFeedTouring,
  offSingleFeedTouring,
  setSingleFeedScrollTop,
  setSingleFeedCacheMap,
  setSingleFeedScrollToIndex,
  setSingleFeedHeaderState,
  initSingleFeedData,
  setFirstSingleFeedData,
  changeSingleFeedData,
  appendSingleFeedData,
  resetSingleFeedDataAll,
  resetRegisterTimestamp,
  setLottieJsons,
  setCampaignLottieJsons,
  changeFeedCache,
} = directFeedSlice.actions;

export default directFeedSlice.reducer;

export const setScrollId = (id: string) => {
  window.sessionStorage.setItem('scroll_id', id);
};
export const removeScrollId = () => {
  window.sessionStorage.removeItem('scroll_id');
};

export const getScrollId = () => {
  return window.sessionStorage.getItem('scroll_id');
};

export const setSourcePath = (fullPath: string) => {
  window.sessionStorage.setItem('source_path', fullPath);
};

export const removeSourcePath = () => {
  window.sessionStorage.removeItem('source_path');
};

export const getSourcePath = () => {
  return window.sessionStorage.getItem('source_path') || '';
};

export const setSessionItem = (id: string, value: string) => {
  window.sessionStorage.setItem(id, value);
};

export const getSessionItem = (id: string) => {
  return window.sessionStorage.getItem(id);
};

export const removeSessionItem = (id: string) => {
  window.sessionStorage.removeItem(id);
};

export const getWriteBtnSourcePath = (pathname: string) => {
  if (pathname.includes('product')) {
    return '/mypage/item/edit';
  }
  if (pathname.includes('board')) {
    return '/board/edit';
  }
  if (pathname.includes('lounge')) {
    return '/lounge/write';
  }
  return '/';
};

import { IStoreSearchState } from 'store/types';
import { IStoreCategory } from 'types/store/storeTypes';
import { TOptionDefault } from '@types';

type CategoryDepth = 1 | 2 | 3 | 4;
type DepthKey = `depth${CategoryDepth}`;

export const DEFAULT_LNB_OBJECT: TOptionDefault = {
  label: '전체',
  value: 'all',
};

export const createCategoryLnbTabList = (categories: IStoreCategory[]) => [
  DEFAULT_LNB_OBJECT,
  ...categories.map(category => ({
    label: category.name,
    value: category.id,
  })),
];

export const updateCategoryDepth = (
  state: IStoreSearchState,
  depth: CategoryDepth,
  selectedCategory: IStoreCategory,
) => {
  const currentDepth = state.category[`depth${depth}` as DepthKey];

  currentDepth.lnbTabKey = selectedCategory.id;

  if (currentDepth.lnbTabList.length === 0) {
    currentDepth.lnbTabList = createCategoryLnbTabList(
      currentDepth.categoryList,
    );
  }

  if (selectedCategory.children?.length > 0 && depth < 4) {
    const nextDepth = state.category[`depth${depth + 1}` as DepthKey];
    nextDepth.categoryList = selectedCategory.children;
    nextDepth.lnbTabList = createCategoryLnbTabList(selectedCategory.children);
    nextDepth.lnbTabKey = 'all';
  }
};

export const resetCategoryDepth = (
  state: IStoreSearchState,
  depth: Exclude<CategoryDepth, 1>,
) => {
  state.category[`depth${depth}` as DepthKey] = {
    categoryList: [],
    lnbTabList: [],
    lnbTabKey: '',
  };
};

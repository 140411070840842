import { getStoreCollectionDetail } from 'api';
import { all, takeLatest } from 'redux-saga/effects';
import { createFetchAction } from 'store/sagas/createFetchAction';
import {
  getStoreCollectionDetailFailure,
  getStoreCollectionDetailMoreRequest,
  getStoreCollectionDetailRequest,
  getStoreCollectionDetailSuccess,
} from 'store/storeCollectionDetail/storeCollectionDetailReducer';

function* getStoreCollectionDetailSaga() {
  yield takeLatest(
    [
      getStoreCollectionDetailRequest.type,
      getStoreCollectionDetailMoreRequest.type,
    ],
    createFetchAction(
      getStoreCollectionDetail,
      getStoreCollectionDetailSuccess,
      getStoreCollectionDetailFailure,
      undefined,
      undefined,
    ),
  );
}

export default function* storeCollectionDetailSaga() {
  yield all([getStoreCollectionDetailSaga()]);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IItemDetail } from '@types';
import {
  createCategoryLnbTabList,
  resetCategoryDepth,
  updateCategoryDepth,
} from 'store/storeSearch/storeSearchReducerUtils';
import { IStoreSearchState } from 'store/types';
import {
  IStoreCategory,
  IStorePopularSearchKeyword,
} from 'types/store/storeTypes';

const initialState: IStoreSearchState = {
  count: 0,
  items: [],
  price: {
    minPrice: 0,
    maxPrice: 0,
  },
  brands: [],
  hotItems: [],
  paging: {
    skip: 0,
    limit: 0,
    hasNext: false,
  },
  error: null,
  autoCompleteKeywordList: [],
  recommendKeywordList: [],
  popularKeywordList: [],

  category: {
    multiLevelCategoryList: [],
    depth1: {
      categoryList: [],
      lnbTabList: [],
      lnbTabKey: '',
    },
    depth2: {
      categoryList: [],
      lnbTabList: [],
      lnbTabKey: '',
    },
    depth3: {
      categoryList: [],
      lnbTabList: [],
      lnbTabKey: '',
    },
    depth4: {
      categoryList: [],
      lnbTabList: [],
      lnbTabKey: '',
    },
  },
};

const storeSearchSlice = createSlice({
  name: 'storeSearch',
  initialState,
  reducers: {
    storeSearchRequest: (
      state,
      action: PayloadAction<{
        search: string;
        minPrice?: number;
        maxPrice?: number;
        brands?: string;
        sort?: string;
        skip: number;
        limit: number;
        category?: string;
        isFirstLoad?: boolean;
      }>,
    ) => {
      const { skip, limit } = action.payload;

      state.paging = {
        skip,
        limit,
        hasNext: true,
      };

      state.items = [];
      state.error = null;
    },
    storeSearchMoreRequest: (
      state,
      action: PayloadAction<{
        search: string;
        minPrice?: number;
        maxPrice?: number;
        brands?: string;
        sort?: string;
        skip: number;
        limit: number;
        category?: string;
      }>,
    ) => {
      const { skip, limit } = action.payload;

      state.paging = {
        skip,
        limit,
        hasNext: true,
      };
      state.error = null;
    },
    storeSearchSuccess: (
      state,
      action: PayloadAction<{
        data: {
          items: IItemDetail[];
          hasNext: boolean;
          price: {
            minPrice: number;
            maxPrice: number;
          };
          brands: {
            brandName: string;
            count: number;
          }[];
          hotItems: IItemDetail[];
          count: number;
        };
        requestPayload: {
          search: string;
          minPrice?: number;
          maxPrice?: number;
          brands?: string;
          sort?: string;
          skip: number;
          limit: number;
          category?: string;
          isFirstLoad?: boolean;
        };
      }>,
    ) => {
      const { items, hasNext, price, brands, hotItems, count } =
        action.payload.data;
      const { minPrice, maxPrice, isFirstLoad } = action.payload.requestPayload;

      state.items = [...state.items, ...items];
      state.paging.hasNext = hasNext;

      if (!minPrice) {
        state.price.minPrice = price.minPrice;
      }
      if (!maxPrice) {
        state.price.maxPrice = price.maxPrice;
      }
      state.brands = brands;
      state.hotItems = hotItems;
      if (isFirstLoad) {
        state.count = count;
      }
      state.error = null;
    },
    storeSearchFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    storeSearchAutoCompleteKeywordRequest: (
      state,
      action: PayloadAction<{ keyword: string }>,
    ) => {
      state.error = null;
    },
    storeSearchAutoCompleteKeywordSuccess: (
      state,
      action: PayloadAction<{ data: { keyword: string[] } }>,
    ) => {
      state.autoCompleteKeywordList = action.payload.data.keyword;
      state.error = null;
    },
    storeSearchAutoCompleteKeywordFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    storeSearchRecommendKeywordRequest: (state, action: PayloadAction) => {
      state.error = null;
    },
    storeSearchRecommendKeywordSuccess: (
      state,
      action: PayloadAction<{ data: { keywords: string[] } }>,
    ) => {
      state.recommendKeywordList = action.payload.data.keywords;
      state.error = null;
    },
    storeSearchRecommendKeywordFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    storeSearchPopularKeywordRequest: (state, action: PayloadAction) => {
      state.error = null;
    },
    storeSearchPopularKeywordSuccess: (
      state,
      action: PayloadAction<{
        data: { popularSearches: IStorePopularSearchKeyword[] };
      }>,
    ) => {
      state.popularKeywordList = action.payload.data.popularSearches;
      state.error = null;
    },
    storeSearchPopularKeywordFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    storeCategoryListRequest: (state, action: PayloadAction) => {
      state.error = null;
    },
    storeCategoryListSuccess: (
      state,
      action: PayloadAction<{ data: { categories: IStoreCategory[] } }>,
    ) => {
      state.category.multiLevelCategoryList = action.payload.data.categories;
      state.category.depth1.categoryList = action.payload.data.categories;
      state.category.depth1.lnbTabList = createCategoryLnbTabList(
        action.payload.data.categories,
      );

      state.category.depth1.lnbTabKey = 'all';
      state.error = null;
    },
    storeCategoryListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    setDepth1Category: (state, action: PayloadAction<IStoreCategory>) => {
      updateCategoryDepth(state, 1, action.payload);
    },

    setDepth2Category: (state, action: PayloadAction<IStoreCategory>) => {
      updateCategoryDepth(state, 2, action.payload);
    },

    setDepth3Category: (state, action: PayloadAction<IStoreCategory>) => {
      updateCategoryDepth(state, 3, action.payload);
    },

    setDepth4Category: (state, action: PayloadAction<IStoreCategory>) => {
      updateCategoryDepth(state, 4, action.payload);
    },

    resetDepth1Category: state => {
      state.category.depth1.lnbTabKey = 'all';
    },

    resetDepth2Category: state => {
      resetCategoryDepth(state, 2);
    },

    resetDepth3Category: state => {
      resetCategoryDepth(state, 3);
    },

    resetDepth4Category: state => {
      resetCategoryDepth(state, 4);
    },

    setLnbTabKey: (
      state,
      action: PayloadAction<{ lnbTabKey: string; depth: 1 | 2 | 3 | 4 }>,
    ) => {
      const { lnbTabKey, depth } = action.payload;
      state.category[`depth${depth}`].lnbTabKey = lnbTabKey;
    },
  },
});

export const {
  storeSearchRequest,
  storeSearchMoreRequest,
  storeSearchSuccess,
  storeSearchFailure,

  storeSearchAutoCompleteKeywordRequest,
  storeSearchAutoCompleteKeywordSuccess,
  storeSearchAutoCompleteKeywordFailure,

  storeSearchRecommendKeywordRequest,
  storeSearchRecommendKeywordSuccess,
  storeSearchRecommendKeywordFailure,

  storeSearchPopularKeywordRequest,
  storeSearchPopularKeywordSuccess,
  storeSearchPopularKeywordFailure,

  storeCategoryListRequest,
  storeCategoryListSuccess,
  storeCategoryListFailure,

  setDepth1Category,
  setDepth2Category,
  setDepth3Category,
  setDepth4Category,

  resetDepth1Category,
  resetDepth2Category,
  resetDepth3Category,
  resetDepth4Category,

  setLnbTabKey,
} = storeSearchSlice.actions;

export default storeSearchSlice.reducer;

import {
  takeEvery,
  all,
  takeLatest,
  put,
  call,
  take,
  select,
} from 'redux-saga/effects';
import {
  getArticlesByProductFailure,
  getArticlesByProductRequest,
  getArticlesByProductSuccess,
  getProductFailure,
  getProductRequest,
  getProductShotsMoreFailure,
  getProductShotsMoreRequest,
  getProductShotsMoreSuccess,
  getProductSuccess,
  reviseProductInfoFailure,
  reviseProductInfoRequest,
  reviseProductInfoSuccess,
  updateTimestamp,
} from 'store/reducers/product';
import {
  getProductDetail,
  getArticlesByProduct,
  reviseProductInfo,
  getProductShotsMore,
} from 'api';
import { showToast } from 'store/reducers/toast';
import {
  IResponse,
  TProductDetail,
  IProduct,
  IProductBrand,
  IPagingMedia,
} from '@types';
import { customHistory } from 'App';
import { PayloadAction } from '@reduxjs/toolkit';
import { IGetProductShotsMoreRequestPayload } from 'store/types';
import { RootState } from 'store/reducers';
import { postFeedbackLog } from 'hooks/useGetTrackId';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function saveRecentProduct(product: TProductDetail) {
  const recents: string | null = localStorage.getItem('products');

  let products: IProduct[] = [];

  if (typeof recents === 'string') {
    products = JSON.parse(recents);
  }

  const sProduct = {
    id: product.id,
    thumbnails: product.thumbnails,
    name: product.name,
    brand: { name: product.brand.name } as IProductBrand,
    priceStartFrom: product.priceStartFrom,
    category: { major: product.category.major },
    normalPrice: product?.normalPrice,
  } as IProduct;

  if (!products.find(c => c.id === sProduct.id)) {
    products = [sProduct, ...products];
  } else {
    products = [sProduct, ...products.filter(c => c.id !== sProduct.id)];
  }

  if (products.length > 10) {
    products = products.slice(0, 10);
  }
  localStorage.setItem('products', JSON.stringify(products));
}

function* getProductSaga() {
  yield takeEvery(
    getProductRequest.type,
    createFetchAction(
      getProductDetail,
      getProductSuccess,
      getProductFailure,
      function* success(data: TProductDetail) {
        yield call(saveRecentProduct, data);

        const visitedPages: string[] = yield select(
          (state: RootState) => state.feedbackLogReducer.visitedPages,
        );

        // // Iteration-7 스토어심폐소생 FeedbackLog-5
        yield postFeedbackLog({
          action: 'view',
          target: 'product',
          targetId: data.id,
          targetParam: {
            item: data.primeItem?.baseInfo.productNo || undefined,
          },
          visitedPages,
        });
      },
      function* fail(error: Error | IResponse) {
        yield failure(error);
        yield customHistory.goBack();
      },
    ),
  );
}

function* getArticlesByProductSaga() {
  yield takeEvery(
    getArticlesByProductRequest.type,
    createFetchAction(
      getArticlesByProduct,
      getArticlesByProductSuccess,
      getArticlesByProductFailure,
    ),
  );
}

function* getProductShotsMoreSaga() {
  yield takeLatest(
    getProductShotsMoreRequest.type,
    createFetchAction(
      getProductShotsMore,
      getProductShotsMoreSuccess,
      getProductShotsMoreFailure,
    ),
  );
}
function* reviseProductInfoSaga() {
  yield takeLatest(
    reviseProductInfoRequest.type,
    createFetchAction(
      reviseProductInfo,
      reviseProductInfoSuccess,
      reviseProductInfoFailure,
      function* success() {
        yield put(showToast('정보 수정요청 완료'));
      },
      failure,
    ),
  );
}

function* getMoreProductShots() {
  while (true) {
    const action: PayloadAction<IGetProductShotsMoreRequestPayload> =
      yield take(updateTimestamp.type);

    yield put(getProductShotsMoreRequest(action.payload));
  }
}

export function* productSaga() {
  yield all([
    getProductSaga(),
    getArticlesByProductSaga(),
    reviseProductInfoSaga(),
    getProductShotsMoreSaga(),
    getMoreProductShots(),
  ]);
}

import { all, takeLatest } from 'redux-saga/effects';
import {
  getStoreCollectionList,
  getStoreShowcaseList,
  getStoreThemeList,
} from 'api';
import {
  getStoreCollectionListRequest,
  getStoreCollectionListSuccess,
  getStoreCollectionListFailure,
  getStoreShowcaseListRequest,
  getStoreShowcaseListSuccess,
  getStoreShowcaseListFailure,
  getStoreThemeListRequest,
  getStoreThemeListSuccess,
  getStoreThemeListFailure,
} from 'store/camfitStoreHome/reducer';
import { createFetchAction } from 'store/sagas/createFetchAction';

function* getStoreCollectionListSaga() {
  yield takeLatest(
    getStoreCollectionListRequest.type,
    createFetchAction(
      getStoreCollectionList,
      getStoreCollectionListSuccess,
      getStoreCollectionListFailure,
    ),
  );
}

function* getStoreShowcaseListSaga() {
  yield takeLatest(
    getStoreShowcaseListRequest.type,
    createFetchAction(
      getStoreShowcaseList,
      getStoreShowcaseListSuccess,
      getStoreShowcaseListFailure,
    ),
  );
}

function* getStoreThemeListSaga() {
  yield takeLatest(
    getStoreThemeListRequest.type,
    createFetchAction(
      getStoreThemeList,
      getStoreThemeListSuccess,
      getStoreThemeListFailure,
    ),
  );
}

export default function* camfitStoreHomeSaga() {
  yield all([
    getStoreCollectionListSaga(),
    getStoreShowcaseListSaga(),
    getStoreThemeListSaga(),
  ]);
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer';
import {
  IFeedCampaign,
  IFeedPostScore,
  IFeedQuration,
  TFeedCache,
  TFeeds,
} from 'types/feed';

interface Props {
  myBrandProfileList: any[];
  brandProfilePopularList: any[];
  feedQuration: IFeedQuration[] | null;
  feedCampaigns: IFeedCampaign[] | null;
  feedAdPostIds: Set<string>;
  feedData: {
    name: string;
    data: TFeeds[];
    registerTimestamp: number;
  }[];
  feedName: string | null;
  activeTabIndex: number;
  page: number[];
  pageSize: number;

  /** 피드 상세 진입 여부 */
  isTouring: boolean;
  scrollY: number;
  /** window 최상단에서 얼마만큼 스크롤 내렸는지 여부. */
  scrollTop: number;
  cacheMap: CellMeasurerCache;
  /** 마지막 노출된 게시물 index */
  scrollToIndex: number;
  /** header의 보여짐 상태 */
  headerState: '' | 'off';
  /** 리액션 lottie */
  lottieJsons: { [key: string]: any };
  /** 캠페인 전용 로띠 */
  campaignLottieJsons: { [key: string]: any };

  feedCache: {
    name: string;
    cache: TFeedCache[];
  }[];

  /** 재측정 필요 여부 */
  needToMeasure: boolean;
  postScore: IFeedPostScore;

  /**
   * 이하 내 게시글 피드 데이터
   */
  myFeedCacheMap: CellMeasurerCache;
  myFeedData: TFeeds[];
  myFeedPage: number;
  myFeedPageSize: number;
  isMyFeedEnd: boolean;
  isMyFeedTouring: boolean;
  myFeedScrollTop: number;
  myFeedScrollToIndex: number;
  myFeedCache: TFeedCache[];

  /**
   * 이하 북마크된 피드 데이터
   */
  bookmarkedFeedCacheMap: CellMeasurerCache;
  bookmarkedFeedData: TFeeds[];
  bookmarkedFeedPage: number;
  bookmarkedFeedPageSize: number;
  isBookmarkedFeedEnd: boolean;
  isBookmarkedFeedTouring: boolean;
  bookmarkedFeedScrollTop: number;
  bookmarkedFeedScrollToIndex: number;
  bookmarkedFeedCache: TFeedCache[];

  /**
   * 이하 내 캠핑로그 피드 데이터
   */
  myCampingLogCacheMap: CellMeasurerCache;
  myCampingLogData: TFeeds[];
  myCampingLogPage: number;
  myCampingLogPageSize: number;
  isMyCampingLogEnd: boolean;
  isMyCampingLogTouring: boolean;
  myCampingLogScrollTop: number;
  myCampingLogScrollToIndex: number;
  myCampingLogCache: TFeedCache[];

  /**
   * 이하 특정 유저 피드 데이터
   */
  userFeedCacheMap: CellMeasurerCache;
  userFeedData: TFeeds[];
  userFeedPage: number;
  userFeedPageSize: number;
  isUserFeedEnd: boolean;
  isUserFeedTouring: boolean;
  userFeedScrollTop: number;
  userFeedScrollToIndex: number;
  userFeedCache: TFeedCache[];

  /**
   * 이하 특정 유저 캠핑로그 데이터
   */
  userCampingLogCacheMap: CellMeasurerCache;
  userCampingLogData: TFeeds[];
  userCampingLogPage: number;
  userCampingLogPageSize: number;
  isUserCampingLogEnd: boolean;
  isUserCampingLogTouring: boolean;
  userCampingLogScrollTop: number;
  userCampingLogScrollToIndex: number;
  userCampingLogCache: TFeedCache[];
}

const initialState: Props = {
  myBrandProfileList: [],
  brandProfilePopularList: [],
  feedQuration: null,
  feedCampaigns: null,
  feedData: [],
  feedAdPostIds: new Set<string>(),
  feedName: null,
  activeTabIndex: 0,
  page: [],
  pageSize: 30,
  isTouring: false,
  scrollY: 0,
  scrollTop: 0,
  cacheMap: new CellMeasurerCache({
    fixedWidth: true,
  }),
  scrollToIndex: 0,
  /** header의 보여짐 여부 컨트롤 */
  headerState: '',
  lottieJsons: {},
  campaignLottieJsons: {},
  feedCache: [],
  postScore: {
    feedId: '',
  },

  /**
   * 이하 내 게시글 피드 변수
   */
  myFeedPage: 1,
  myFeedCacheMap: new CellMeasurerCache({
    fixedWidth: true,
  }),
  myFeedData: [],
  myFeedPageSize: 30,
  isMyFeedEnd: false,
  isMyFeedTouring: false,
  myFeedScrollTop: 0,
  myFeedScrollToIndex: 0,
  myFeedCache: [],
  needToMeasure: false,

  /**
   * 이하 북마크용 피드 변수
   */
  bookmarkedFeedPage: 1,
  bookmarkedFeedCacheMap: new CellMeasurerCache({
    fixedWidth: true,
  }),
  bookmarkedFeedData: [],
  bookmarkedFeedPageSize: 30,
  isBookmarkedFeedEnd: false,
  isBookmarkedFeedTouring: false,
  bookmarkedFeedScrollTop: 0,
  bookmarkedFeedScrollToIndex: 0,
  bookmarkedFeedCache: [],

  /**
   * 이하 캠핑로그 피드 변수
   */
  myCampingLogPage: 1,
  myCampingLogCacheMap: new CellMeasurerCache({
    fixedWidth: true,
  }),
  myCampingLogData: [],
  myCampingLogPageSize: 30,
  isMyCampingLogEnd: false,
  isMyCampingLogTouring: false,
  myCampingLogScrollTop: 0,
  myCampingLogScrollToIndex: 0,
  myCampingLogCache: [],

  /**
   * 이하 특정 유저 게시글 변수
   */
  userFeedCacheMap: new CellMeasurerCache({
    fixedWidth: true,
  }),
  userFeedData: [],
  userFeedPage: 1,
  userFeedPageSize: 30,
  isUserFeedEnd: false,
  isUserFeedTouring: false,
  userFeedScrollTop: 0,
  userFeedScrollToIndex: 0,
  userFeedCache: [],

  /**
   * 이하 특정 유저 캠핑로그 변수
   */
  userCampingLogCacheMap: new CellMeasurerCache({
    fixedWidth: true,
  }),
  userCampingLogData: [],
  userCampingLogPage: 1,
  userCampingLogPageSize: 30,
  isUserCampingLogEnd: false,
  isUserCampingLogTouring: false,
  userCampingLogScrollTop: 0,
  userCampingLogScrollToIndex: 0,
  userCampingLogCache: [],
};

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    setMyBrandProfileList: (state, action: PayloadAction<any[]>) => {
      state.myBrandProfileList = action.payload;
    },
    setBrandProfilePopularList: (state, action: PayloadAction<any[]>) => {
      state.brandProfilePopularList = action.payload;
    },
    setFeedQuration: (state, action: PayloadAction<IFeedQuration[]>) => {
      state.feedQuration = action.payload;
    },
    setFeedCampaigns: (state, action: PayloadAction<IFeedCampaign[]>) => {
      state.feedCampaigns = action.payload;
    },
    setCampaignLottieJsons: (
      state,
      action: PayloadAction<{ id: string; jsonData: string }>,
    ) => {
      const { id, jsonData } = action.payload;
      state.campaignLottieJsons = {
        ...state.campaignLottieJsons,
        [id]: jsonData,
      };
    },
    setFeedName: (state, action: PayloadAction<string>) => {
      state.feedName = action.payload;
    },
    setActiceIndexTab: (state, action: PayloadAction<number>) => {
      state.activeTabIndex = action.payload;
    },
    setPage: (
      state,
      action: PayloadAction<{ activeTabIndex: number; value: number }>,
    ) => {
      const { activeTabIndex, value } = action.payload;

      state.page[activeTabIndex] = value;
    },
    setScrollY: (state, action: PayloadAction<number>) => {
      state.scrollY = action.payload;
    },
    setScrollTop: (state, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
    setCacheMap: (state, action: PayloadAction<CellMeasurerCache>) => {
      state.cacheMap = action.payload;
    },
    setScrollToIndex: (state, action: PayloadAction<number>) => {
      state.scrollToIndex = action.payload;
    },
    onTouring: state => {
      state.isTouring = true;
    },
    offTouring: state => {
      state.isTouring = false;
    },

    setHeaderState: (state, action: PayloadAction<'' | 'off'>) => {
      state.headerState = action.payload;
    },

    initFeedData: (state, action: PayloadAction<string[]>) => {
      const feedNames = action.payload;

      state.page = new Array(feedNames.length).fill(1);

      state.feedData = feedNames.map(feedName => {
        return {
          name: feedName,
          data: [],
          registerTimestamp: 0,
        };
      });

      state.feedCache = feedNames.map(feedName => {
        return {
          name: feedName,
          cache: [],
        };
      });
    },

    setFirstFeedData: (
      state,
      action: PayloadAction<{
        name: string;
        data: TFeeds[];
      }>,
    ) => {
      const { name, data } = action.payload;

      const targetIndex = state.feedData.findIndex(feed => feed.name === name);
      state.page[targetIndex] = 1;

      state.feedData = state.feedData.map(feed =>
        feed.name !== name
          ? feed
          : {
              name,
              data,
              registerTimestamp: new Date().getTime(),
            },
      );

      state.feedCache = state.feedCache.map(cache =>
        cache.name !== name
          ? cache
          : {
              name,
              cache: data.map(el => {
                return {
                  isMoreBtnClicked: false,
                  isCampInfoShow: false,
                };
              }),
            },
      );
    },

    changeFeedData: (
      state,
      action: PayloadAction<{ name: string; data: TFeeds[] }>,
    ) => {
      const { name, data } = action.payload;

      state.feedData = state.feedData.map(feed =>
        feed.name !== name
          ? feed
          : { name, data, registerTimestamp: new Date().getTime() },
      );
    },

    appendFeedData: (
      state,
      action: PayloadAction<{ name: string; data: TFeeds[] }>,
    ) => {
      const { name, data } = action.payload;

      state.feedData = state.feedData.map(feed =>
        feed.name !== name
          ? feed
          : {
              name,
              data: [...feed.data, ...data],
              registerTimestamp: new Date().getTime(),
            },
      );

      const appendCache =
        name === 'Review'
          ? new Array(data.length).fill({
              isMoreBtnClicked: false,
              isCampInfoShow: false,
            })
          : new Array(data.length).fill({ isMoreBtnClicked: false });

      state.feedCache = state.feedCache.map(cache =>
        cache.name !== name
          ? cache
          : {
              name,
              cache: [...cache.cache, ...appendCache],
            },
      );
    },

    appendFeedAdPostId: (state, action: PayloadAction<string>) => {
      const feedId = action.payload;
      state.feedAdPostIds.add(feedId);
    },

    resetFeedDataAll: state => {
      state.isTouring = false;
      state.scrollTop = 0;
      state.cacheMap = new CellMeasurerCache({
        fixedWidth: true,
      });
      state.scrollToIndex = 0;
      state.headerState = '';
      // state.feedAdPostIds = new Set();
    },

    /**
     * 로그인 시 새로운 데이터를 받아오기 위해 registerTimestamp reset 처리
     * 참조 슬랙
     * @description https://nexteditionworkspace.slack.com/archives/C06QPRFSBRD/p1724461843839659
     */
    resetRegisterTimestamp: state => {
      state.feedData = state.feedData.map(feed => {
        return {
          ...feed,
          registerTimestamp: 0,
        };
      });
    },

    setLottieJsons: (
      state,
      action: PayloadAction<{ id: string; jsonData: string }>,
    ) => {
      const { id, jsonData } = action.payload;
      state.lottieJsons = { ...state.lottieJsons, [id]: jsonData };
    },

    changeFeedCache: (
      state,
      action: PayloadAction<{
        name: string;
        data: TFeedCache[];
      }>,
    ) => {
      const { name, data } = action.payload;
      state.feedCache = state.feedCache.map(cache =>
        cache.name !== name
          ? cache
          : {
              name,
              cache: data,
            },
      );
    },

    setNeedToMeasure: (state, action: PayloadAction<boolean>) => {
      state.needToMeasure = action.payload;
    },

    initPostScore: (state, action: PayloadAction<string>) => {
      state.postScore = {
        feedId: action.payload,
      };
    },

    appendPostScore: (
      state,
      action: PayloadAction<{
        key: keyof Omit<IFeedPostScore, 'feedId'>;
        postId: string;
        postPoolId?: string;
      }>,
    ) => {
      const key = action.payload.key;
      const postId = action.payload.postId;
      const postPoolId = action.payload.postPoolId;

      const payload = postPoolId
        ? { post: postId, postPoolId }
        : { post: postId };

      const prevValue = state.postScore[key];

      if (prevValue) {
        prevValue.push(payload);
      } else {
        state.postScore[key] = [payload];
      }
    },

    setNeedToSendPostScore: (state, action: PayloadAction<string>) => {},

    /**
     * 이하 내 게시글 피드 리듀서
     */

    setMyFeedData: (state, action: PayloadAction<TFeeds[]>) => {
      state.myFeedData = action.payload;
      state.myFeedCache = new Array(action.payload.length).fill({
        isMoreBtnClicked: false,
      });
    },
    addMyFeedData: (state, action: PayloadAction<TFeeds[]>) => {
      if (state.myFeedData && state.myFeedData.length > 0) {
        state.myFeedData.push(...action.payload);
        state.myFeedCache.push(
          ...new Array(action.payload.length).fill({
            isMoreBtnClicked: false,
          }),
        );
      }
    },
    setMyFeedPage: (state, action: PayloadAction<number>) => {
      state.myFeedPage = action.payload;
    },
    setMyFeedCacheMap: (state, action: PayloadAction<CellMeasurerCache>) => {
      state.myFeedCacheMap = action.payload;
    },
    onMyFeedTouring: state => {
      state.isMyFeedTouring = true;
    },
    offMyFeedTouring: state => {
      state.isMyFeedTouring = false;
    },
    setMyFeedScrollTop: (state, action: PayloadAction<number>) => {
      state.myFeedScrollTop = action.payload;
    },
    setMyFeedScrollToIndex: (state, action: PayloadAction<number>) => {
      state.myFeedScrollToIndex = action.payload;
    },
    resetMyFeedDataAll: state => {
      state.myFeedData = [];
      state.myFeedPage = 1;
      state.isMyFeedTouring = false;
      state.myFeedScrollTop = 0;
      state.myFeedCacheMap = new CellMeasurerCache({
        fixedWidth: true,
      });
      state.myFeedScrollToIndex = 0;
    },
    changeMyFeedData: (
      state,
      action: PayloadAction<{ index: number; data: TFeeds }>,
    ) => {
      const { index, data } = action.payload;
      state.myFeedData = state.myFeedData.map((feed, i) =>
        i !== index ? feed : data,
      );
    },

    changeMyFeedCache: (state, action: PayloadAction<TFeedCache[]>) => {
      state.myFeedCache = action.payload;
    },

    /**
     * 이하 북마크된 피드 리듀서
     */
    setBookmarkedFeedData: (state, action: PayloadAction<TFeeds[]>) => {
      state.bookmarkedFeedData = action.payload;

      const cache = action.payload.map(el => {
        if (el.type === 'review') {
          return {
            isMoreBtnClicked: false,
            isCampInfoShow: false,
          };
        }
        return {
          isMoreBtnClicked: false,
        };
      });
      state.bookmarkedFeedCache = cache;
    },
    addBookmarkedFeedData: (state, action: PayloadAction<TFeeds[]>) => {
      if (state.bookmarkedFeedData && state.bookmarkedFeedData.length > 0) {
        state.bookmarkedFeedData.push(...action.payload);

        const newCache = action.payload.map(el => {
          if (el.type === 'review') {
            return {
              isMoreBtnClicked: false,
              isCampInfoShow: false,
            };
          }
          return {
            isMoreBtnClicked: false,
          };
        });
        state.bookmarkedFeedCache.push(...newCache);
      }
    },
    setBookmarkedFeedPage: (state, action: PayloadAction<number>) => {
      state.bookmarkedFeedPage = action.payload;
    },
    setBookmarkedFeedCacheMap: (
      state,
      action: PayloadAction<CellMeasurerCache>,
    ) => {
      state.bookmarkedFeedCacheMap = action.payload;
    },
    onBookmarkedFeedTouring: state => {
      state.isBookmarkedFeedTouring = true;
    },
    offBookmarkedFeedTouring: state => {
      state.isBookmarkedFeedTouring = false;
    },
    setBookmarkedFeedScrollTop: (state, action: PayloadAction<number>) => {
      state.bookmarkedFeedScrollTop = action.payload;
    },
    setBookmarkedFeedScrollToIndex: (state, action: PayloadAction<number>) => {
      state.bookmarkedFeedScrollToIndex = action.payload;
    },
    resetBookmarkedFeedDataAll: state => {
      state.bookmarkedFeedData = [];
      state.bookmarkedFeedPage = 1;
      state.isBookmarkedFeedTouring = false;
      state.bookmarkedFeedScrollTop = 0;
      state.bookmarkedFeedCacheMap = new CellMeasurerCache({
        fixedWidth: true,
      });
      state.bookmarkedFeedScrollToIndex = 0;
    },
    changeBookmarkedFeedData: (
      state,
      action: PayloadAction<{ index: number; data: TFeeds }>,
    ) => {
      const { index, data } = action.payload;
      state.bookmarkedFeedData = state.bookmarkedFeedData.map((feed, i) =>
        i !== index ? feed : data,
      );
    },

    // 북마크 해제 시 북마크에서는 해당 데이터를 지워줘야함.
    deleteBookmarkedFeedData: (state, action: PayloadAction<number>) => {
      const targetIndex = action.payload;
      state.bookmarkedFeedData = state.bookmarkedFeedData.filter(
        (feed, i) => i !== targetIndex,
      );

      state.bookmarkedFeedCache = state.bookmarkedFeedCache.filter(
        (feed, i) => i !== targetIndex,
      );
    },

    changeBookmarkedFeedCache: (state, action: PayloadAction<TFeedCache[]>) => {
      state.bookmarkedFeedCache = action.payload;
    },

    /**
     * 이하 내 캠핑로그 피드 리듀서
     */
    setMyCampingLogData: (state, action: PayloadAction<TFeeds[]>) => {
      state.myCampingLogData = action.payload;
      state.myCampingLogCache = new Array(action.payload.length).fill({
        isMoreBtnClicked: false,
        isCampInfoShow: false,
      });
    },
    addMyCampingLogData: (state, action: PayloadAction<TFeeds[]>) => {
      if (state.myCampingLogData && state.myCampingLogData.length > 0) {
        state.myCampingLogData.push(...action.payload);
        state.myCampingLogCache.push(
          ...new Array(action.payload.length).fill({
            isMoreBtnClicked: false,
            isCampInfoShow: false,
          }),
        );
      }
    },
    setMyCampingLogPage: (state, action: PayloadAction<number>) => {
      state.myCampingLogPage = action.payload;
    },
    setMyCampingLogCacheMap: (
      state,
      action: PayloadAction<CellMeasurerCache>,
    ) => {
      state.myCampingLogCacheMap = action.payload;
    },
    onMyCampingLogTouring: state => {
      state.isMyCampingLogTouring = true;
    },
    offMyCampingLogTouring: state => {
      state.isMyCampingLogTouring = false;
    },
    setMyCampingLogScrollTop: (state, action: PayloadAction<number>) => {
      state.myCampingLogScrollTop = action.payload;
    },
    setMyCampingLogScrollToIndex: (state, action: PayloadAction<number>) => {
      state.myCampingLogScrollToIndex = action.payload;
    },
    resetMyCampingLogDataAll: state => {
      state.myCampingLogData = [];
      state.myCampingLogPage = 1;
      state.isMyCampingLogTouring = false;
      state.myCampingLogScrollTop = 0;
      state.myCampingLogCacheMap = new CellMeasurerCache({
        fixedWidth: true,
      });
      state.myCampingLogScrollToIndex = 0;
    },
    changeMyCampingLogData: (
      state,
      action: PayloadAction<{ index: number; data: TFeeds }>,
    ) => {
      const { index, data } = action.payload;
      state.myCampingLogData = state.myCampingLogData.map((feed, i) =>
        i !== index ? feed : data,
      );
    },

    changeMyCampingLogCache: (state, action: PayloadAction<TFeedCache[]>) => {
      state.myCampingLogCache = action.payload;
    },

    /**
     * 이하 특정 유저 게사글 피드 리듀서
     */
    setUserFeedData: (state, action: PayloadAction<TFeeds[]>) => {
      state.userFeedData = action.payload;
      state.userFeedCache = new Array(action.payload.length).fill({
        isMoreBtnClicked: false,
      });
    },
    addUserFeedData: (state, action: PayloadAction<TFeeds[]>) => {
      if (state.userFeedData && state.userFeedData.length > 0) {
        state.userFeedData.push(...action.payload);
        state.userFeedCache.push(
          ...new Array(action.payload.length).fill({
            isMoreBtnClicked: false,
          }),
        );
      }
    },
    setUserFeedPage: (state, action: PayloadAction<number>) => {
      state.userFeedPage = action.payload;
    },
    setUserFeedCacheMap: (state, action: PayloadAction<CellMeasurerCache>) => {
      state.userFeedCacheMap = action.payload;
    },
    onUserFeedTouring: state => {
      state.isUserFeedTouring = true;
    },
    offUserFeedTouring: state => {
      state.isUserFeedTouring = false;
    },
    setUserFeedScrollTop: (state, action: PayloadAction<number>) => {
      state.userFeedScrollTop = action.payload;
    },
    setUserFeedScrollToIndex: (state, action: PayloadAction<number>) => {
      state.userFeedScrollToIndex = action.payload;
    },
    resetUserFeedDataAll: state => {
      state.userFeedData = [];
      state.userFeedPage = 1;
      state.isUserFeedTouring = false;
      state.userFeedScrollTop = 0;
      state.userFeedCacheMap = new CellMeasurerCache({
        fixedWidth: true,
      });
      state.userFeedScrollToIndex = 0;
    },
    changeUserFeedData: (
      state,
      action: PayloadAction<{ index: number; data: TFeeds }>,
    ) => {
      const { index, data } = action.payload;
      state.userFeedData = state.userFeedData.map((feed, i) =>
        i !== index ? feed : data,
      );
    },

    changeUserFeedCache: (state, action: PayloadAction<TFeedCache[]>) => {
      state.userFeedCache = action.payload;
    },

    /**
     * 이하 특정 유저 캠핑로그 피드 리듀서
     */
    setUserCampingLogData: (state, action: PayloadAction<TFeeds[]>) => {
      state.userCampingLogData = action.payload;
      state.userCampingLogCache = new Array(action.payload.length).fill({
        isMoreBtnClicked: false,
        isCampInfoShow: false,
      });
    },
    addUserCampingLogData: (state, action: PayloadAction<TFeeds[]>) => {
      if (state.userCampingLogData && state.userCampingLogData.length > 0) {
        state.userCampingLogData.push(...action.payload);
        state.userCampingLogCache.push(
          ...new Array(action.payload.length).fill({
            isMoreBtnClicked: false,
            isCampInfoShow: false,
          }),
        );
      }
    },
    setUserCampingLogPage: (state, action: PayloadAction<number>) => {
      state.userCampingLogPage = action.payload;
    },
    setUserCampingLogCacheMap: (
      state,
      action: PayloadAction<CellMeasurerCache>,
    ) => {
      state.userCampingLogCacheMap = action.payload;
    },
    onUserCampingLogTouring: state => {
      state.isUserCampingLogTouring = true;
    },
    offUserCampingLogTouring: state => {
      state.isUserCampingLogTouring = false;
    },
    setUserCampingLogScrollTop: (state, action: PayloadAction<number>) => {
      state.userCampingLogScrollTop = action.payload;
    },
    setUserCampingLogScrollToIndex: (state, action: PayloadAction<number>) => {
      state.userCampingLogScrollToIndex = action.payload;
    },
    resetUserCampingLogDataAll: state => {
      state.userCampingLogData = [];
      state.userCampingLogPage = 1;
      state.isUserCampingLogTouring = false;
      state.userCampingLogScrollTop = 0;
      state.userCampingLogCacheMap = new CellMeasurerCache({
        fixedWidth: true,
      });
      state.userCampingLogScrollToIndex = 0;
    },
    changeUserCampingLogData: (
      state,
      action: PayloadAction<{ index: number; data: TFeeds }>,
    ) => {
      const { index, data } = action.payload;
      state.userCampingLogData = state.userCampingLogData.map((feed, i) =>
        i !== index ? feed : data,
      );
    },

    changeUserCampingLogCache: (state, action: PayloadAction<TFeedCache[]>) => {
      state.userCampingLogCache = action.payload;
    },
  },
});

export const {
  setMyBrandProfileList,
  setBrandProfilePopularList,
  setFeedQuration,
  setFeedCampaigns,
  setFeedName,
  setActiceIndexTab,
  setPage,
  onTouring,
  offTouring,
  setScrollY,
  setScrollTop,
  setCacheMap,
  setScrollToIndex,
  setHeaderState,
  initFeedData,
  setFirstFeedData,
  changeFeedData,
  appendFeedData,
  resetFeedDataAll,
  resetRegisterTimestamp,
  setLottieJsons,
  setCampaignLottieJsons,
  changeFeedCache,
  setNeedToMeasure,
  initPostScore,
  appendPostScore,
  appendFeedAdPostId,
  setNeedToSendPostScore,

  setMyFeedData,
  addMyFeedData,
  setMyFeedPage,
  setMyFeedCacheMap,
  onMyFeedTouring,
  offMyFeedTouring,
  setMyFeedScrollTop,
  setMyFeedScrollToIndex,
  resetMyFeedDataAll,
  changeMyFeedData,
  changeMyFeedCache,

  setBookmarkedFeedData,
  addBookmarkedFeedData,
  setBookmarkedFeedPage,
  setBookmarkedFeedCacheMap,
  onBookmarkedFeedTouring,
  offBookmarkedFeedTouring,
  setBookmarkedFeedScrollTop,
  setBookmarkedFeedScrollToIndex,
  resetBookmarkedFeedDataAll,
  changeBookmarkedFeedData,
  deleteBookmarkedFeedData,
  changeBookmarkedFeedCache,

  setMyCampingLogData,
  addMyCampingLogData,
  setMyCampingLogPage,
  setMyCampingLogCacheMap,
  onMyCampingLogTouring,
  offMyCampingLogTouring,
  setMyCampingLogScrollTop,
  setMyCampingLogScrollToIndex,
  resetMyCampingLogDataAll,
  changeMyCampingLogData,
  changeMyCampingLogCache,

  setUserFeedData,
  addUserFeedData,
  setUserFeedPage,
  setUserFeedCacheMap,
  onUserFeedTouring,
  offUserFeedTouring,
  setUserFeedScrollTop,
  setUserFeedScrollToIndex,
  resetUserFeedDataAll,
  changeUserFeedData,
  changeUserFeedCache,

  setUserCampingLogData,
  addUserCampingLogData,
  setUserCampingLogPage,
  setUserCampingLogCacheMap,
  onUserCampingLogTouring,
  offUserCampingLogTouring,
  setUserCampingLogScrollTop,
  setUserCampingLogScrollToIndex,
  resetUserCampingLogDataAll,
  changeUserCampingLogData,
  changeUserCampingLogCache,
} = feedSlice.actions;

export default feedSlice.reducer;

import { all, takeLatest } from 'redux-saga/effects';
import {
  createFetchAction,
  createFetchActionWithKey,
} from 'store/sagas/createFetchAction';
import {
  getStoreStoryCollectionDetailSuccess,
  getStoreStoryCollectionDetailFailure,
  getStoreStoryCollectionDetailRequest,
  getStoreStoryCollectionDetailItemRequest,
  getStoreStoryCollectionDetailItemMoreRequest,
  getStoreStoryCollectionDetailItemSuccess,
  getStoreStoryCollectionDetailItemFailure,
} from 'store/storeStoryCollectionDetail/storeStoryCollectionDetailReducer';
import {
  getStoreCollectionDetail,
  getStoreStoryCollectionDetailItem,
} from 'api';

function* getStoreStoryCollectionDetailSaga() {
  yield takeLatest(
    getStoreStoryCollectionDetailRequest.type,
    createFetchAction(
      getStoreCollectionDetail,
      getStoreStoryCollectionDetailSuccess,
      getStoreStoryCollectionDetailFailure,
      undefined,
      undefined,
    ),
  );
}

function* getStoreStoryCollectionDetailItemSaga() {
  yield takeLatest(
    [
      getStoreStoryCollectionDetailItemRequest.type,
      getStoreStoryCollectionDetailItemMoreRequest.type,
    ],
    createFetchActionWithKey(
      getStoreStoryCollectionDetailItem,
      getStoreStoryCollectionDetailItemSuccess,
      getStoreStoryCollectionDetailItemFailure,
      undefined,
      undefined,
    ),
  );
}

export default function* storeStoryCollectionDetailSaga() {
  yield all([
    getStoreStoryCollectionDetailSaga(),
    getStoreStoryCollectionDetailItemSaga(),
  ]);
}

import styled, { CSSProp } from 'styled-components';
import Container from '@material-ui/core/Container';
import { theme } from 'static/styles/theme';

interface Style {
  bg?: keyof typeof theme.colors;
  $backgroundColor?: keyof typeof theme.newColors;
  $isFixed?: boolean;
  top?: number;
  css?: CSSProp;
  $padding?: string;
  $bgColor?: string;
}

const PageContainer = styled(Container)<Style>`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: ${({ theme, bg, $backgroundColor }) =>
    $backgroundColor
      ? theme.newColors[$backgroundColor as keyof typeof theme.newColors]
      : theme.colors[bg as keyof typeof theme.colors]};

  ${({ $isFixed, top }) =>
    $isFixed &&
    `
    position: fixed;
    ${top ? `top: -${top}px` : ''};
    overflow: hidden;
    max-width: 530px;
    height: 100%;

    @media screen and (max-width: 960px) {
      left: 50vw;
      transform: translate(-50%);
    }
`}
`;

export default PageContainer;

export const NewPageContainer = styled.div<Style>`
  max-width: 530px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  padding-left: 0;
  padding-right: 0;
  padding: ${({ $padding }) => $padding || 0};

  position: relative;
  width: 100%;
  height: auto;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: ${({ theme, $backgroundColor, $bgColor }) =>
    $bgColor ||
    theme.newColors[$backgroundColor as keyof typeof theme.newColors]};
  overflow-x: hidden;

  &.sticky-header-included {
    overflow-x: inherit;
  }

  ${({ $isFixed, top }) =>
    $isFixed &&
    `
    position: fixed;
    ${top ? `top: -${top}px` : ''};
    overflow: hidden;
    max-width: 530px;
    height: 100%;

    @media screen and (max-width: 960px) {
      left: 50vw;
      transform: translate(-50%);
    }
  `}

  @media screen and (max-width: 960px) {
    margin: 0 auto;
  }

  ${({ css }) => css}
`;

export const NewVerticalPageContainer = styled(NewPageContainer)`
  display: flex;
  flex-direction: column;

  .vertical {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
  }
`;

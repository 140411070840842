import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IShopbyItemDetailReviewState,
  IShopbyReviewListState,
  IShopbyReviewListResponsePayload,
  IShopbyReviewListRequestPayload,
  IShopbyItemDetailPhotoReviewState,
  IShopbyPhotoReviewListRequestPayload,
  IShopbyPhotoReviewListResponsePayload,
  IShopbyCurrentPhtoReviewState,
  IShopbyPhotoReviewDetailRequestPayload,
  IShopbyPostReviewReportRequestPayload,
} from 'store/types';
import { IShopbyReviewDetail, ITShopbyErrorRes } from '@types';

const itemReviewInitialState: IShopbyItemDetailReviewState = {
  totalCount: 0,
  rate: 0,
  reviewRatingResponses: [],
  reviewList: [],
  pageNumber: 1,
  pageSize: 10,
  hasMore: true,
  shopbyError: undefined,
};

const photoReviewInitialState: IShopbyItemDetailPhotoReviewState = {
  totalCount: 0,
  totalPage: 0,
  photoReviewList: [],
  pageNumber: 1,
  pageSize: 5,
  hasMore: true,
  shopbyError: undefined,
};

const currentPhotoReviewDetailInitialState: IShopbyCurrentPhtoReviewState = {
  currentPhotoReview: {} as IShopbyReviewDetail,
  shopbyError: undefined,
};

const initialState: IShopbyReviewListState = {
  review: itemReviewInitialState,
  sampleReview: itemReviewInitialState,
  photoReview: photoReviewInitialState,
  samplePhotoReview: photoReviewInitialState,
  currentPhotoReviewInfo: currentPhotoReviewDetailInitialState,
  shopbyError: undefined,
};

export const shopbyReviewListSlice = createSlice({
  name: 'shopbyReviewList',
  initialState,
  reducers: {
    // sampleItemReview
    getSampleShopbyItemReviewListRequest: (
      state,
      action: PayloadAction<IShopbyReviewListRequestPayload>,
    ) => {
      // state.sampleReview.totalCount = 0;
      // state.sampleReview.rate = 0;
      // state.sampleReview.reviewRatingResponses = [];
      // state.sampleReview.reviewList = [];
      state.sampleReview.hasMore = true;
      state.sampleReview.shopbyError = undefined;
    },
    getSampleShopbyItemReviewListSuccess: (
      state,
      action: PayloadAction<{
        data: IShopbyReviewListResponsePayload;
      }>,
    ) => {
      const { totalCount, rate, reviewRatingResponses, items } =
        action.payload.data;

      state.sampleReview.totalCount = totalCount;
      state.sampleReview.rate = rate;
      state.sampleReview.reviewRatingResponses = reviewRatingResponses;
      state.sampleReview.reviewList = items;
      state.sampleReview.shopbyError = undefined;
    },
    getSampleShopbyItemReviewListFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.sampleReview.shopbyError = action.payload;
    },

    // itemReview
    getShopbyItemReviewListRequest: (
      state,
      action: PayloadAction<IShopbyReviewListRequestPayload>,
    ) => {
      // state.review.totalCount = 0;
      // state.review.rate = 0;
      // state.review.reviewRatingResponses = [];
      // state.review.reviewList = [];
      state.review.hasMore = true;
      state.review.shopbyError = undefined;
    },
    getMoreShopbyItemReviewListRequest: (
      state,
      action: PayloadAction<IShopbyReviewListRequestPayload>,
    ) => {
      const { pageNumber } = action.payload.parameters;
      state.review.pageNumber = pageNumber;
      state.review.shopbyError = undefined;
    },
    getShopbyItemReviewListSuccess: (
      state,
      action: PayloadAction<{
        data: IShopbyReviewListResponsePayload;
      }>,
    ) => {
      const { totalCount, rate, reviewRatingResponses, items } =
        action.payload.data;

      if (state.review.pageNumber === 1) {
        state.review.reviewList = items;
      } else {
        state.review.reviewList = state.review.reviewList.concat(items);
      }

      state.review.totalCount = totalCount;
      state.review.hasMore = state.review.reviewList.length < totalCount;
      state.review.rate = rate;
      state.review.reviewRatingResponses = reviewRatingResponses;
      state.review.shopbyError = undefined;
    },
    getShopbyItemReviewListFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.review.shopbyError = action.payload;
    },

    // sampleItemPhotoReview
    getSampleShopbyItemPhotoReviewListRequest: (
      state,
      action: PayloadAction<IShopbyPhotoReviewListRequestPayload>,
    ) => {
      // state.samplePhotoReview.totalCount = 0;
      // state.samplePhotoReview.totalPage = 0;
      // state.samplePhotoReview.photoReviewList = [];
      state.samplePhotoReview.hasMore = true;
      state.samplePhotoReview.shopbyError = undefined;
    },
    getSampleShopbyItemPhotoReviewListSuccess: (
      state,
      action: PayloadAction<{
        data: IShopbyPhotoReviewListResponsePayload;
      }>,
    ) => {
      const { totalCount, contents, totalPage } = action.payload.data;

      state.samplePhotoReview.totalCount = totalCount;
      state.samplePhotoReview.photoReviewList = contents;

      state.samplePhotoReview.hasMore =
        state.photoReview.pageNumber <= totalPage;

      state.samplePhotoReview.shopbyError = undefined;
    },
    getSampleShopbyItemPhotoReviewListFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.samplePhotoReview.shopbyError = action.payload;
    },

    // ItemPhotoReview
    getShopbyItemPhotoReviewListRequest: (
      state,
      action: PayloadAction<IShopbyPhotoReviewListRequestPayload>,
    ) => {
      // state.photoReview.totalCount = 0;
      // state.photoReview.totalPage = 0;
      // state.photoReview.photoReviewList = [];
      state.photoReview.hasMore = true;
      state.photoReview.shopbyError = undefined;
    },
    getMoreShopbyItemPhotoReviewListRequest: (
      state,
      action: PayloadAction<IShopbyPhotoReviewListRequestPayload>,
    ) => {
      const { pageNumber } = action.payload.parameters;
      state.photoReview.pageNumber = pageNumber;
      state.photoReview.shopbyError = undefined;
    },
    getShopbyItemPhotoReviewListSuccess: (
      state,
      action: PayloadAction<{ data: IShopbyPhotoReviewListResponsePayload }>,
    ) => {
      const { totalCount, contents, totalPage } = action.payload.data;

      if (state.photoReview.pageNumber === 1) {
        state.photoReview.photoReviewList = contents;
      } else {
        state.photoReview.photoReviewList =
          state.photoReview.photoReviewList.concat(contents);
      }

      state.photoReview.totalCount = totalCount;
      state.photoReview.hasMore = state.photoReview.pageNumber <= totalPage;

      state.photoReview.shopbyError = undefined;
    },
    getShopbyItemPhotoReviewListFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.photoReview.shopbyError = action.payload;
    },
    resetShopbyItemPhotoReviewList: state => {
      state.photoReview = photoReviewInitialState;
    },

    // ItemPhotoReviewDetail
    getShopbyItemPhotoReviewDetailRequest: (
      state,
      action: PayloadAction<IShopbyPhotoReviewDetailRequestPayload>,
    ) => {
      state.currentPhotoReviewInfo.currentPhotoReview =
        {} as IShopbyReviewDetail;
      state.currentPhotoReviewInfo.shopbyError = undefined;
    },
    getShopbyItemPhotoReviewDetailSuccess: (
      state,
      action: PayloadAction<{
        data: IShopbyReviewDetail;
      }>,
    ) => {
      state.currentPhotoReviewInfo.currentPhotoReview = action.payload.data;
      state.currentPhotoReviewInfo.shopbyError = undefined;
    },
    getShopbyItemPhotoReviewDetailFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.photoReview.shopbyError = action.payload;
    },

    // ItemReviewReport
    postShopbyItemReviewReportRequest: (
      state,
      action: PayloadAction<IShopbyPostReviewReportRequestPayload>,
    ) => {
      state.currentPhotoReviewInfo.shopbyError = undefined;
    },
    postShopbyItemReviewReportSuccess: (state, action) => {
      state.currentPhotoReviewInfo.shopbyError = undefined;
    },
    postShopbyItemReviewReportFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.currentPhotoReviewInfo.shopbyError = action.payload;
    },

    resetShopbyReview: (
      state,
      action: PayloadAction<
        | 'review'
        | 'sampleReview'
        | 'photoReview'
        | 'samplePhotoReview'
        | 'currentPhotoReviewInfo'
      >,
    ) => {
      if (action.payload === 'review') {
        state.review = itemReviewInitialState;
      } else if (action.payload === 'sampleReview') {
        state.sampleReview = itemReviewInitialState;
      } else if (action.payload === 'photoReview') {
        state.photoReview = photoReviewInitialState;
      } else if (action.payload === 'samplePhotoReview') {
        state.samplePhotoReview = photoReviewInitialState;
      } else if (action.payload === 'currentPhotoReviewInfo') {
        state.currentPhotoReviewInfo = currentPhotoReviewDetailInitialState;
      } else {
        state.shopbyError = undefined;
      }
    },

    resetShopbyError: (
      state,
      action: PayloadAction<
        'review' | 'photoReview' | 'currentPhotoReviewInfo' | 'state'
      >,
    ) => {
      if (action.payload === 'review') {
        state.review.shopbyError = undefined;
      } else if (action.payload === 'photoReview') {
        state.photoReview.shopbyError = undefined;
      } else if (action.payload === 'currentPhotoReviewInfo') {
        state.currentPhotoReviewInfo.shopbyError = undefined;
      } else {
        state.shopbyError = undefined;
      }
    },
  },
});

export const {
  getSampleShopbyItemReviewListRequest,
  getSampleShopbyItemReviewListSuccess,
  getSampleShopbyItemReviewListFailure,

  getShopbyItemReviewListRequest,
  getMoreShopbyItemReviewListRequest,
  getShopbyItemReviewListSuccess,
  getShopbyItemReviewListFailure,

  getSampleShopbyItemPhotoReviewListRequest,
  getSampleShopbyItemPhotoReviewListSuccess,
  getSampleShopbyItemPhotoReviewListFailure,

  getShopbyItemPhotoReviewListRequest,
  getMoreShopbyItemPhotoReviewListRequest,
  getShopbyItemPhotoReviewListSuccess,
  getShopbyItemPhotoReviewListFailure,
  resetShopbyItemPhotoReviewList,

  getShopbyItemPhotoReviewDetailRequest,
  getShopbyItemPhotoReviewDetailSuccess,
  getShopbyItemPhotoReviewDetailFailure,

  postShopbyItemReviewReportRequest,
  postShopbyItemReviewReportSuccess,
  postShopbyItemReviewReportFailure,

  resetShopbyReview,
  resetShopbyError,
} = shopbyReviewListSlice.actions;

export default shopbyReviewListSlice.reducer;
